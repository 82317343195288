import { useState, useContext, useCallback } from "react";
import { useOn } from "@kuindji/observable-react";
// import { useNavigate } from "react-router-dom";

import ChatContext from "common/src/lib/chat/ChatContext";

// import Button from "common/src/refactor/components/button/Button";
import MoodboardCard from "common/src/refactor/components/moodboard/Card";

function MessageMoodboard({ message }) {
    // const navigate = useNavigate();
    const chat = useContext(ChatContext);
    const [moodboard, setMoodboard] = useState(() =>
        chat.getMoodboardCache().find((c) => c.id === message.moodboardId)
    );

    const updateMoodboard = useCallback(
        () =>
            !moodboard &&
            setMoodboard(
                chat
                    .getMoodboardCache()
                    .find((c) => c.id === message.moodboardId)
            ),
        [moodboard]
    );

    // const onViewClick = useCallback(() => {
    //     navigate(`/moodboards/${moodboard.id}`);
    // }, [navigate, moodboard]);

    useOn(chat, "moodboards", updateMoodboard);

    if (!moodboard) {
        return null;
    }

    return <MoodboardCard moodboard={moodboard} onClick="default" />;
}

export default MessageMoodboard;
