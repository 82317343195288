import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Chat from "common/src/refactor/components/chat_v3/Chat";
import Page from "common/src/refactor/components/Page";

import FeedDialog from "app/components/feed/FeedDialog";
import LookDialog from "app/components/look/Dialog";
import CatalogueProductDrawer from "common/src/refactor/components/catalogue/product/Drawer";
import ConsultationsDialog from "common/src/refactor/components/consultation/Dialog";
import LooksDialog from "common/src/refactor/components/look/Dialog";
import MoodboardsDialog from "common/src/refactor/components/moodboard/Dialog";

import useHub from "common/src/hooks/useHub";
import hub from "common/src/hub";
import user from "common/src/user";

function PageChat() {
    const navigate = useNavigate();
    const { contactId } = useParams();

    const currentUserRole = useMemo(
        () => {
            if (user.isOnly("Admin")) {
                return "concierge";
            }
            if (user.is("GPS") || user.is("FRI")) {
                return "shopper";
            }
            return "customer";
        },
        [],
    );

    const reviewLook = useCallback(
        async (look, productId) => {
            const prodInx = productId
                ? look.products.findIndex(p => p.id === productId)
                : null;
            LookDialog.show(look, null, prodInx || 0);
        },
        [],
    );

    const reviewCatalogueProduct = useCallback(
        (product) => {
            CatalogueProductDrawer.show({ product });
        },
        [],
    );

    const onMessageAction = useCallback(
        (action, item, productId) => {
            if (action === "review-look") {
                reviewLook(item, productId);
            }
            else if (action === "review-catalogue") {
                reviewCatalogueProduct(item);
            }
            else if (action === "view-image") {
                hub.dispatch(
                    "app",
                    "view-image",
                    item,
                );
            }
        },
        // eslint-disable-next-line
        [],
    );

    const openContact = useCallback(
        (contact) => {
            if (contactId !== contact.id) {
                navigate(`/chat/${contact.id}`);
            }
        },
        [ contactId, navigate ],
    );

    useHub("chat", "set-contact", openContact);

    const onAttachFromFeed = useCallback(
        () => FeedDialog.show("chat-feed-dialog"),
        [],
    );

    const onAttachMoodboard = useCallback(
        () => {
            return MoodboardsDialog.show({
                onlyMine: !user.is("Admin"),
            });
        },
        [],
    );

    const onAttachConsultation = useCallback(
        () => {
            return ConsultationsDialog.show({ contactId });
        },
        [ contactId ],
    );

    const onAttachLook = useCallback(
        () => {
            return LooksDialog.show({ contactId });
        },
        [ contactId ],
    );

    return (
        <Page
            passthrough
            htmlClassName="html-page-chat html-hide-footer html-100h">
            <Chat
                currentUserRole={currentUserRole}
                contactId={contactId}
                showContacts
                onOpenContact={openContact}
                onAttachFromFeed={onAttachFromFeed}
                onAttachMoodboard={onAttachMoodboard}
                onAttachConsultation={onAttachConsultation}
                onAttachLook={onAttachLook}
                onMessageAction={onMessageAction} />
            <FeedDialog dialogName="chat-feed-dialog" />
            <MoodboardsDialog dialogName="chat-moodboards-dialog" />
        </Page>
    );
}

export default PageChat;
