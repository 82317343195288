import { useMemo, useState, useCallback } from "react";

import useQuery from "common/src/refactor/hooks/useQuery";
import { loadRetailers } from "common/src/actions/catalogue/retailers";
import useUpdateEffect from "common/src/hooks/useUpdateEffect";

function retailer2option(retailer) {
    return {
        key: retailer.id,
        label: retailer.name,
        value: retailer.id,
        commission: parseInt(retailer.commission * 100),
        disabled: !!retailer.disabled,
    };
}

export default function useRetailerSelector({
    onItemsChange,
    itemToOption,
    region,
} = {}) {
    const [query, setQuery] = useState("");

    const { data: retailersList, isLoading } = useQuery(
        loadRetailers,
        [region, query],
        {
            params: {
                query,
                region,
                withCommission: true,
            },
        },
        [query, region]
    );

    const search = useCallback((query) => {
        setQuery(query);
    }, []);

    const retailers = useMemo(
        () =>
            retailersList.filter((r) => {
                if (r.visible === false) {
                    return false;
                }
                if (query) {
                    return r.name.toLowerCase().includes(query.toLowerCase());
                }
                return true;
            }),
        [retailersList, query]
    );

    const options = useMemo(
        () => retailers.map(itemToOption || retailer2option),
        [retailers, itemToOption]
    );

    useUpdateEffect(() => {
        onItemsChange?.(retailersList);
    }, [retailersList]);

    return {
        options,
        isLoading,
        search,
    };
}
