import api from "app/api";
import getClickDomain from "common/src/lib/url/clickDomain";
import hub from "common/src/hub";

export default async function getShortLink(productInfo) {
    const res = await api.backend.post("/link", { body: productInfo });
    const clickDomain = getClickDomain({ retailer: productInfo.retailer });

    hub.dispatch("posthog", "event", {
        event: "created_trackable_link",
        payload: {
            retailer: productInfo.retailer,
            link: `https://${clickDomain}/s/${res.hash}`,
        },
    });

    hub.dispatch("user-analytics", "change", {
        linkLastCreatedAt: new Date().toISOString(),
    });

    return `https://${clickDomain}/s/${res.hash}`;
}
