// import user from "common/src/user";
// import hub from "common/src/hub";
import api from "app/api";
import preloadImages from "common/src/lib/image/preload";
import s3url from "common/src/lib/image/s3url";

export async function saveProductImages(product) {
    const images = product.images;
    if (!images || images.length === 0) {
        return [];
    }
    if (typeof images[0] !== "string") {
        return images;
    }
    let promises = images.map(async (url) => {
        return api.backend.post("/upload/remote", {
            body: {
                url,
            },
        });
    });
    const results = await Promise.all(promises);
    promises = results.map(async (r) => {
        const { key, originalUrl } = r;
        const [ info ] = await preloadImages([ originalUrl ]);
        if (info) {
            info.src = key ? s3url(key) : originalUrl;
            info.originalUrl = originalUrl;
            return info;
        }
        else {
            return {
                src: originalUrl,
                originalUrl: originalUrl,
            };
        }
    });
    const newImages = await Promise.all(promises);
    return newImages;
}

export async function createProductReference(product, metadata = null) {
    const productId = product.id;
    const region = product.region;
    const where = {
        productId: { _eq: productId },
        region: { _eq: region },
    };
    const ex = await api.catalogueProductReference
        .list({ where })
        .then((l) => l[0]);
    if (ex) {
        return ex.id;
    }
    // const images = await saveProductImages(product);

    // if (metadata !== null && typeof metadata !== "string") {
    //     metadata = JSON.stringify(metadata);
    // }

    if (!metadata) {
        const images = product.cdn_images || product.images;
        if (images && images.length > 0) {
            const image = images[0];
            const size = getImageSize(image);
            metadata = {
                width: size.width,
                height: size.height,
                ratio: size.ratio,
            };
        }
    }

    const data = {
        productId,
        region,
        // images,
        metadata,
    };
    const resp = await api.catalogueProductReference.create(data);
    return resp.id;
}

export async function setReaction({
    product = null,
    reaction,
    userId,
    moodboardId = null,
}) {
    if (!product) {
        throw new Error("No product or referencedProductId provided");
    }

    const productReferenceId = await createProductReference(product);
    userId = userId || user.id();

    const prev = await api.userReaction
        .list({
            where: {
                productReferenceId,
                userId,
            },
        })
        .then((l) => l[0]);

    if (prev) {
        if (reaction) {
            const payload = {
                reaction,
                createdAt: new Date().toISOString(),
            };
            if (moodboardId && !prev.moodboardId) {
                payload.moodboardId = moodboardId;
            }
            await api.userReaction.update(prev.id, payload);
        }
        else {
            await api.userReaction.remove(prev.id);
        }
    }
    else if (reaction) {
        const payload = {
            productReferenceId,
            userId,
            reaction,
        };
        if (moodboardId) {
            payload.moodboardId = moodboardId;
        }
        await api.userReaction.create(payload);
    }

    hub.dispatch("catalogue-product", "reaction-changed", {
        productId: product.id,
        productReferenceId,
        reaction,
    });
}
