import { Flex } from "antd";
import ExpandableFilter from "common/src/refactor/components/ExpandableFilter";
import { useCallback } from "react";
import useRetailerSelector from "./hooks/useRetailerSelector";

export default function RetailerExpandable(
    { value = [], group, region, onChange, onItemsChange },
) {
    const { options, isLoading, search } = useRetailerSelector({
        onItemsChange,
        region,
    });

    const renderLabel = useCallback(
        (item) => {
            if (!item) {
                return null;
            }
            return (
                <Flex justify="space-between" align="center">
                    <span>{item.label}</span>
                    {(!!item.commission && item.commission > 0
                        && !item.disabled)
                        && (
                            <span className="expandable-filter-badge">
                                {item.commission}%
                            </span>
                        )}
                </Flex>
            );
        },
        [],
    );

    return (
        <ExpandableFilter
            searchable={false}
            showAll
            group={group}
            name="Retailer"
            searchPlaceholder="Search retailers"
            multiple={true}
            items={options}
            loading={isLoading}
            onSearch={search}
            value={value}
            onChange={onChange}
            renderOption={renderLabel} />
    );
}
