import { useMemo } from "react";

import cf from "common/src/lib/format/currency";

function useLookProductData(product) {
    const {
        price = 0,
        salePrice = 0,
        currency = "GBP",
        availability = "",
    } = useMemo(() => {
        if (!product) {
            return {};
        }
        const p = product,
            // stock = p.stock || {},
            price = p.min_price,
            salePrice = p.sale_price,
            currency = p.currency,
            availability = p.availability;

        return { price, salePrice, currency, availability };
    }, [product?.min_price, product?.currency, product?.min_sale_price]);

    const isSale = useMemo(
        () => !!salePrice && salePrice !== price,
        [price, currency, salePrice]
    );
    const isSold = useMemo(
        () => availability === "out-of-stock",
        [availability]
    );
    const priceStr = useMemo(
        () => (price ? cf(price, currency) : ""),
        [price, currency]
    );
    const salePriceStr = useMemo(
        () => (salePrice ? cf(salePrice, currency) : ""),
        [salePrice, currency]
    );

    return {
        price,
        salePrice,
        currency,
        availability,
        retailer: product?.retailer,
        retailerName: product?.retailer,
        brand: product?.brand,
        isSale,
        isSold,
        priceStr,
        salePriceStr,
    };
}

export default useLookProductData;
