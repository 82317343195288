import { Flex, Spin } from "antd";
import { useCallback, useContext, useMemo, useRef, useState } from "react";

import Button from "common/src/refactor/components/button/Button";

import Spacer from "common/src/refactor/components/Spacer";
// import FeedDialogToolbar from "app/components/feed/FeedDialogToolbar"
import PartitionToolbar from "common/src/refactor/components/catalogue/PartitionToolbar";
import ProductCard from "common/src/refactor/components/catalogue/product/Card";
import CatalogueProductSelect from "common/src/refactor/components/catalogue/product/Select";
import FeedSearchBar from "common/src/refactor/components/catalogue/SearchBar";
import EmptyMessage from "common/src/refactor/components/EmptyMessage";
import { ReactComponent as IconUp } from "common/src/svg/angle-up.svg";

// import useContextData from "common/src/refactor/hooks/useContextData"
import useDictRef from "common/src/hooks/useDictRef";
import useProductCatalogue from "common/src/refactor/hooks/useProductCatalogue";
import { FilterApiContextProvider } from "common/src/refactor/lib/FilterApi";

import * as productEditor from "common/src/actions/look/product";
import useThrottleCallback from "common/src/hooks/useThrottleCallback";
import useUpdateEffect from "common/src/hooks/useUpdateEffect";
import async from "common/src/lib/js/async";
import BackToTop from "common/src/refactor/components/catalogue/BackToTop";
import FeedMessages from "common/src/refactor/components/catalogue/FeedMessages";
import SideFilters from "common/src/refactor/components/catalogue/SideFilters";
import { Portal } from "common/src/refactor/components/Portal";
import useSelection from "common/src/refactor/hooks/useSelection";
import FilterApi from "common/src/refactor/lib/FilterApi";
import LookBuilderContext from "../LookBuilderContext";

function LookBuilderCatalogue({ open = false }) {
    const builder = useContext(LookBuilderContext);
    const filters = useMemo(() => new FilterApi(), []);

    const {
        selection,
        toggle: toggleSelection,
        isSelected,
    } = useSelection({ multiple: false });
    const selectionCount = useMemo(
        () => Object.keys(selection).length,
        [ selection ],
    );
    const {
        products,
        loadMore,
        hasMore,
        loading,
        loaded,
        messages,
        isError,
        takingTooLong,
    } = useProductCatalogue({
        filters,
        setName: "look-builder",
        clearOnUnmount: false,
    });
    const ref = useDictRef({ hasMore, loading, loadMore });
    const [ adding, setAdding ] = useState(false);
    const scrollRef = useRef();

    const onProductClick = useCallback(
        async (product) => {
            toggleSelection(product.id);
        },
        [ toggleSelection ],
    );

    const onSelectorChange = useCallback(
        (id, state) => {
            toggleSelection(id);
        },
        [ toggleSelection ],
    );

    const onAddProductClick = useCallback(
        async () => {
            setAdding(true);
            const sel = selection.map((uid) =>
                products.find((p) => p.id === uid)
            );
            let selectedProduct = sel[0];
            if (selectedProduct) {
                const product = { ...selectedProduct };
                product.referencedProductId = product.id;
                product.referencedProduct = selectedProduct;
                product.id = "tmp-" + product.id;
                // product = productEditor.normalizeCatalogueProduct(product);
                if (product.images) {
                    product.images = await productEditor.preloadProductImages(
                        product.images,
                    );
                }

                builder.trigger("search-select-product", product);
            }
            setAdding(false);
        },
        [ selection, builder, products ],
    );

    const onScroll = useThrottleCallback(
        (e) => {
            const el = e.target;
            ref.scrollTop = el.scrollTop;
            if (ref.hasMore && !ref.loading) {
                if (el.scrollTop / (el.scrollHeight - el.offsetHeight) > 0.75) {
                    ref.loadMore();
                }
            }
        },
        // eslint-disable-next-line
        [],
        50,
        {
            trailing: true,
        },
    );

    useUpdateEffect(
        () => {
            if (open && scrollRef.current && ref.scrollTop) {
                async(() => scrollRef.current.scrollTo({ top: ref.scrollTop }));
            }
        },
        [ open ],
    );

    if (!open) {
        return null;
    }

    return (
        <FilterApiContextProvider value={filters}>
            <Flex
                gap="0.5rem"
                className="look-builder-search-catalogue-filters">
                <SideFilters useQueryString={false} />
                <div className="look-builder-search-catalogue">
                    <Spacer size="0rem" />
                    <FeedSearchBar
                        useQueryString={false}
                        searchButtonType="secondary" />
                    <PartitionToolbar
                        useQueryString={false}
                        toolbarSize="small" />

                    <div
                        className="feed-dialog-body"
                        onScroll={onScroll}
                        ref={scrollRef}>
                        {loaded && !loading && products.length === 0 && (
                            <EmptyMessage
                                size="small"
                                message="No products matched your search" />
                        )}
                        <FeedMessages messages={messages} />
                        {products.length > 0 && (
                            <div className="feed-dialog-products">
                                {products.map((p) => (
                                    <ProductCard
                                        key={p.id}
                                        product={p}
                                        onClick={onProductClick}
                                        children={
                                            <CatalogueProductSelect
                                                id={p.id}
                                                selected={isSelected(p.id)}
                                                onChange={onSelectorChange} />
                                        } />
                                ))}
                            </div>
                        )}
                        {(hasMore || !loaded) && !isError && (
                            <Spin
                                tip={takingTooLong
                                    ? "Sorry, this is taking a bit longer than expected"
                                    : undefined}
                                children={<>&nbsp;</>}
                                spinning
                                className="infinite-scroll-spinner"
                                wrapperClassName="infinite-scroll-spinner-wrapper" />
                        )}
                        {isError && products.length > 0 && (
                            <FeedMessages messages={messages} type="error" />
                        )}
                    </div>
                    <Portal host="look-builder-search-footer">
                        <div className="look-builder-dialog-footer">
                            <Button
                                loading={adding}
                                disabled={selectionCount === 0 || adding}
                                text="Add to look"
                                type="primary"
                                size="large"
                                onClick={onAddProductClick} />
                            <BackToTop elemRef={scrollRef}>
                                <Button
                                    type="text"
                                    size="medium"
                                    text="Back to top"
                                    variant="link"
                                    Icon={IconUp}
                                    iconPosition="after" />
                            </BackToTop>
                        </div>
                    </Portal>
                </div>
            </Flex>
        </FilterApiContextProvider>
    );
}

export default LookBuilderCatalogue;
