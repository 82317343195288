import { useCallback, useContext, useMemo, useState } from "react";
import { App as AntApp } from "antd";

import MoodboardsDialog from "common/src/refactor/components/moodboard/Dialog";
import ConnectionSelector from "common/src/refactor/components/dialog/ConnectionSelector";

import * as moodboardActions from "common/src/actions/moodboard";
import getShortLink from "common/src/lib/catalogue/getShortLink";
import user from "common/src/user";
import Chat from "common/src/lib/chat/Chat";
import LinkSourceContext from "common/src/refactor/lib/LinkSourceContext";

function useProductActions({
    product,
    onTrackableLinkCreated,
    connectionSelectorName,
    moodboardId,
}) {
    const source = useContext(LinkSourceContext);
    const { message, modal } = AntApp.useApp();
    const [addingToMoodboard, setAddingToMoodboard] = useState(false);
    const [sending, setSending] = useState(false);
    const [removingFromMoodboard, setRemovingFromMoodboard] = useState(false);
    const [generatingLink, setGeneratingLink] = useState(false);
    const products = useMemo(
        () => (Array.isArray(product) ? product : [product]),
        [product]
    );

    const loading =
        addingToMoodboard || generatingLink || sending || removingFromMoodboard;

    const onSendClick = useCallback(async () => {
        const conns = await ConnectionSelector.show(
            { multiple: true },
            connectionSelectorName
        );

        const isPlainUser = user.isOnly("User");

        if (!conns) {
            return;
        }

        setSending(true);

        for (let i = 0; i < conns.length; i++) {
            const chat = new Chat({
                detached: true,
                currentUserRole: isPlainUser ? "customer" : "shopper",
            });
            await chat.setContact(conns[i]);
            for (let i = 0; i < products.length; i++) {
                let p = products[i];

                if (p.isLocal) {
                    chat.addAttachment("catalogue", p, p.id);
                } else if (p.id) {
                    chat.addAttachment("catalogue", p, p.id);
                }
            }

            await chat.send();
        }

        if (conns.length === 1) {
            message.success({
                content: `Product sent to ${conns[0].givenName} ${conns[0].familyName}`,
                icon: <></>,
            });
        } else {
            const names = conns
                .map((c) => `${c.givenName} ${c.familyName}`)
                .join(", ");

            message.success({
                content: `Product sent to ${names}`,
                icon: <></>,
            });
        }

        setSending(false);
    }, [connectionSelectorName, message, products]);

    const onAddToMoodboardClick = useCallback(async () => {
        const ms = await MoodboardsDialog.show({ onlyMine: true });
        if (ms && ms.length > 0) {
            setAddingToMoodboard(true);
            for (const m of ms) {
                await moodboardActions.addProducts(m.id, [product]);
            }
            setAddingToMoodboard(false);

            message.success({
                content: "Product added to Moodboard",
                icon: <></>,
            });
        }
    }, [product, message]);

    const onDeleteProductClick = useCallback(() => {
        const id = product.id;

        if (id) {
            setRemovingFromMoodboard(true);

            modal.confirm({
                title: "Delete product",
                content: "Are you sure?",
                closable: true,
                icon: null,
                cancelButtonProps: {
                    type: "text",
                },

                onOk: async () => {
                    await moodboardActions.removeProducts(moodboardId, [id]);
                    setRemovingFromMoodboard(true);
                },
                onCancel: () => setRemovingFromMoodboard(false),
            });
        }
    }, [moodboardId, modal, product]);

    const onTrackableLinkClick = useCallback(async () => {
        setGeneratingLink(true);

        const p = product;
        const url = p.url || p.click_url;

        const link = await getShortLink({
            catalogueProductId: p.id,
            region: p.region,
            url,
            name: p.name,
            brand: p.brand || null,
            sku: p?.details?.sku || null,
            retailer: p.retailer_id,
            source,
        });

        onTrackableLinkCreated && onTrackableLinkCreated(link);
        setGeneratingLink(false);
    }, [product, onTrackableLinkCreated, source]);

    const productApi = {
        onSendClick,

        onDeleteProductClick,
        removingFromMoodboard,

        onAddToMoodboardClick,
        addingToMoodboard,

        onTrackableLinkClick,
        generatingLink,

        loading,
    };

    if (user.isOnly("Admin")) {
        productApi.onSendClick = null;
    }

    return productApi;
}

export default useProductActions;
