import { batch } from "react-redux";

import api from "app/api";
import store from "app/store";
import { data, ui, sets } from "common/src/store/catalogue";
import user from "common/src/user";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { allApiKeys } from "common/src/refactor/lib/FilterApi";

const catalogueSetRequestCache = {};

export const catalogueFilterKeys = allApiKeys;

export const PER_PAGE = 50;

function prepareRequestBody(options) {
    const body = {};
    const { perPage = PER_PAGE } = options;

    catalogueFilterKeys.forEach((k) => {
        if (options[k]) {
            body[k] = options[k];
        }
    });

    body["page_size"] = perPage;
    body["with_retailer_commission"] = true;

    if (!("region" in body)) {
        body["region"] = "gb";
        body["convert_to_currency"] = "GBP";
    }

    return body;
}

export async function catalogueLoader(options, callbacks = {}) {
    const body = prepareRequestBody(options);

    const storeState = store.getState();
    const requestId = new Date().getTime();

    let misc, prevBody, prevPage, productIds;
    let {
        displayCurrency,
        page = 0,
        perPage = PER_PAGE,
        setName = null,
        append = false,
        withReactions = false,
        reactionsUserId = user.id(),
    } = options;

    if (setName) {
        catalogueSetRequestCache[setName] = requestId;
        misc = storeState.catalogue.ui.products.misc;
        prevBody = misc[setName]?.filters || null;
        prevPage = misc[setName]?.page || 0;
        productIds = misc[setName]?.productIds || [];
    } else {
        prevBody = storeState.catalogue.ui.products.filter;
        prevPage = storeState.catalogue.ui.products.page || 0;
        productIds = storeState.catalogue.ui.products.misc?.productIds || [];
    }

    batch(() => {
        if (setName) {
            misc = Object.assign({}, misc, {
                [setName]: {
                    ...misc[setName],
                    loading: true,
                },
            });
            store.dispatch(ui.products.setMisc(misc));
        } else {
            store.dispatch(ui.products.loading(true));
        }
    });

    let products,
        hasMore = false;

    if (page > 0) {
        const start = page * perPage;
        const ids = productIds.slice(start, start + perPage);
        if (ids.length > 0) {
            body["id"] = ids;
            const response = await api.catalogue.post("/search", { body });
            products = response.product;
            hasMore = start + perPage < productIds.length;
        } else {
            products = [];
            hasMore = false;
        }
    } else {
        if (setName) {
            store.dispatch(sets.products.set({ name: setName, value: [] }));
        }
        const response = await api.catalogue.post("/search", { body });
        products = response.product;
        productIds = response.id;
        hasMore = productIds.length > products.length;
    }

    if (setName && catalogueSetRequestCache[setName] !== requestId) {
        return;
    }

    if (callbacks.prefetchImages) {
        const pfimgRes = callbacks.prefetchImages(products);
        if (pfimgRes instanceof Promise) {
            await pfimgRes;
        }
    }

    const pids = products.map((p) => p.id);
    let puids = products.map((p) => p.id);

    if (withReactions) {
        if (user.loggedIn() && pids.length > 0) {
            const where = {
                catalogueProductReference: { productId: { _in: pids } },
                userId: { _eq: reactionsUserId },
            };
            const reactions = await api.userReaction.list({ where });
            reactions.forEach((r) => {
                const p = products.find(
                    (p) => p.id === r.catalogueProductReference.productId
                );
                if (p) {
                    p.reaction = r.reaction;
                }
            });
        }
    }

    batch(() => {
        if (setName) {
            misc = Object.assign({}, misc, {
                [setName]: {
                    ...misc[setName],
                    loading: false,
                    loaded: true,
                    displayCurrency,
                    filters: body,
                    page,
                    hasMore,
                    productIds,
                },
            });
            store.dispatch(ui.products.setMisc(misc));
        } else {
            store.dispatch(ui.products.filter(body));
            store.dispatch(ui.products.page(page));
            store.dispatch(ui.products.loading(false));
            store.dispatch(ui.products.hasMore(hasMore));

            if (page === 0) {
                store.dispatch(ui.products.misc({ productIds }));
            }
        }

        store.dispatch(data.products.merge(products));

        if (append) {
            const exSet = storeState.catalogue.sets.products[setName];
            if (!exSet) {
                store.dispatch(
                    sets.products.set({ name: setName, value: puids })
                );
            } else {
                store.dispatch(
                    sets.products.append({ name: setName, value: puids })
                );
            }
        } else {
            store.dispatch(sets.products.set({ name: setName, value: puids }));
        }
    });

    return { products, ids: productIds, hasMore };
}

export const setManualProducts = (setName, products) => {
    const storeState = store.getState();
    let misc = storeState.catalogue.ui.products.misc;
    misc = Object.assign({}, misc, {
        [setName]: {
            ...misc[setName],
            loading: false,
            loaded: true,
            count: products.length,
            filters: {},
            page: 0,
        },
    });
    batch(() => {
        store.dispatch(data.products.append(products));
        store.dispatch(
            sets.products.set({
                name: setName,
                value: products.map((p) => p.id),
            })
        );
        store.dispatch(ui.products.setMisc(misc));
    });
};

export function useProductSet(setName) {
    const products = useSelector((s) => s.catalogue.data.products);
    const ids = useSelector((s) => s.catalogue.sets.products[setName]);
    const set = useMemo(() => {
        if (!ids) {
            return [];
        }
        return ids.map((id) => products.find((p) => p.id === id));
    }, [ids]);
    return set;
}

export const getProductsBySet = (setName) => {
    const state = store.getState();
    const products = state.catalogue.data.products;
    const ids = state.catalogue.sets.products[setName] || [];
    return ids.map((id) => products.find((p) => p.id === id));
};

export const clearSet = (setName) => {
    const storeState = store.getState();
    const misc = { ...storeState.catalogue.ui.products.misc };
    delete misc[setName];

    batch(() => {
        store.dispatch(sets.products.unset(setName));
        store.dispatch(ui.products.setMisc(misc));
    });
};
