import api from "app/api";
import store from "app/store";
import { createProductReference } from "common/src/actions/catalogueLocal";
import hub from "common/src/hub";
import preloadImages from "common/src/lib/image/preload";
import s3url from "common/src/lib/image/s3url";
import { productScheme, ui } from "common/src/store/look/product";

export async function preloadProductImages(productImages, sortImages = false) {
    let images = await preloadImages(
        productImages
            .filter((value, index, self) => self.indexOf(value) === index)
            .slice(0, 10),
    );

    if (sortImages) {
        images = images.sort(function(a, b) {
            let s1 = 1,
                s2 = 1;
            a.width && a.height && (s1 = a.width * a.height);
            b.width && b.height && (s2 = b.width * b.height);
            return s1 < s2 ? -1 : s1 === s2 ? 0 : 1;
        });

        images = images.reverse();
    }

    const downloadable = true;

    images.forEach((i) => (i.downloadable = downloadable));

    return images;
}

export async function submit(product, lookId, productMode) {
    let editMode = product.id && product.id.indexOf("tmp-") !== 0,
        prodId = editMode ? product.id : null;

    try {
        let now = new Date().toISOString(),
            prodData = {},
            key;

        for (key in productScheme) {
            prodData[key] = null; // create writable property

            if (key === "images") {
                prodData[key] = product[key].map((i) => ({ ...i }));
            }
            else if (product[key] !== "") {
                prodData[key] = product[key]; // assign value from immutable object
            }
        }

        delete prodData.id;
        delete prodData.productStyles;
        delete prodData.designers;

        prodData.description = prodData.description === null
            ? ""
            : prodData.description;
        prodData.image = "";
        // prodData.images = [];

        !editMode && (prodData.createdAt = prodData.createdAt || now);
        prodData.updatedAt = now;
        prodData.price && (prodData.price = parseFloat(prodData.price));
        prodData.lookId = lookId;

        if (prodData.originalImages) {
            prodData.originalImages = JSON.stringify(prodData.originalImages);
        }

        if (!editMode && product.referencedProduct) {
            prodData.productReferenceId = await createProductReference(
                product.referencedProduct,
            );
        }

        /// UPLOAD IMAGE / REMOVE BG

        if (!editMode && prodData.images.length > 0) {
            const promises = [];

            for (const imageData of prodData.images) {
                const inx = prodData.images.indexOf(imageData);

                promises.push(
                    new Promise(async (resolve) => {
                        // console.log("start image", imageData.src)

                        let s3key, removeBg;
                        removeBg = imageData.removeBg;

                        if (imageData.local) {
                            removeBg = false;
                            s3key = await api.backend
                                .post("/upload/image", {
                                    body: {
                                        // name: uuidv4() + "." + mime2ext(product.imageData.mime),
                                        contentType: product.imageData.mime,
                                        data: product.imageData.data,
                                    },
                                })
                                .then((resp) => resp.key);
                        }
                        else if (imageData.src) {
                            s3key = await api.backend
                                .post("/upload/remote", {
                                    body: {
                                        url: imageData.src,
                                        removeBg,
                                    },
                                })
                                .then((resp) => {
                                    removeBg = resp.bgRemoved;
                                    return resp.key;
                                });
                        }

                        if (imageData.uploadKey) {
                            await api.backend.post("/upload/delete", {
                                body: { uploadKey: imageData.uploadKey },
                            });
                        }

                        if (imageData.uploadKey) {
                            await api.backend.post("/upload/delete", {
                                body: { uploadKey: imageData.uploadKey },
                            });
                        }

                        try {
                            await preloadImages([
                                s3key
                                    ? s3url(s3key)
                                    : !imageData.local && imageData.src
                                    ? imageData.src
                                    : null,
                            ]).then((images) => {
                                const image = images[0] || {};
                                if (
                                    !image.src
                                    && !imageData.local
                                    && imageData.src
                                ) {
                                    image.src = imageData.src;
                                    image.bgRemoved = false;
                                }
                                else {
                                    image.key = s3key;
                                    image.bgRemoved = removeBg;
                                }
                                if (typeof prodData.images === "string") {
                                    const parsed = JSON.parse(prodData.images);
                                    parsed[inx] = image;
                                    prodData.images = JSON.stringify(parsed);
                                }
                                else {
                                    prodData.images[inx] = image;
                                }
                            });
                        }
                        catch (err) {
                            console.log(err);
                            delete prodData.images[inx];
                        }
                        resolve();
                    }),
                );
            }

            // console.log("awaiting all images", promises.length);
            await Promise.allSettled(promises);
            // console.log("all images resolved")
            // console.log("all images done");

            prodData.images = JSON.stringify(prodData.images);
        }
        else if (editMode) {
            const promises = [];

            for (const imageData of product.images) {
                promises.push(
                    new Promise(async (resolve) => {
                        // console.log("start image", imageData.key || imageData.src)
                        const inx = product.images.indexOf(imageData);
                        let bgRemoved = false,
                            newImagesAdded = false,
                            s3key = null;

                        // update existing image
                        if (imageData.key) {
                            if (imageData.removeBg) {
                                try {
                                    s3key = await api.backend
                                        .post("/removebg/remove", {
                                            body: {
                                                productId: editMode
                                                    ? prodId
                                                    : null,
                                                index: inx,
                                                key: imageData.key,
                                            },
                                        })
                                        .then((resp) => resp.key);

                                    try {
                                        await preloadImages([
                                            s3url(s3key, 1000),
                                        ]).then((images) => {
                                            const image = images[0];
                                            image.key = s3key;
                                            image.bgRemoved = true;
                                            if (
                                                typeof prodData.images
                                                    === "string"
                                            ) {
                                                const parsed = JSON.parse(
                                                    prodData.images,
                                                );
                                                parsed[inx] = image;
                                                prodData.images = JSON
                                                    .stringify(parsed);
                                            }
                                            else {
                                                prodData.images[inx] = image;
                                            }
                                        });
                                    }
                                    catch (err) {
                                        console.log(err);
                                        delete prodData.images[inx];
                                    }
                                }
                                catch (err) {
                                    console.log(err);
                                    // console.log(err, cloneDeep(product.images[i]))
                                    // logger.log(err, cloneDeep(product.images[i]));
                                }
                                bgRemoved = true;
                            }
                        }
                        // upload new image
                        else {
                            bgRemoved = false;

                            try {
                                // console.log("upload remote", cloneDeep(product.images[i]))
                                s3key = await api.backend
                                    .post("/upload/remote", {
                                        body: {
                                            url: imageData.src,
                                            removeBg: imageData.removeBg
                                                || false,
                                        },
                                    })
                                    .then((resp) => {
                                        bgRemoved = resp.bgRemoved;
                                        return resp.key;
                                    });
                                // console.log(s3key)
                            }
                            catch (err) {
                                s3key = "";
                                console.log(err);
                                // console.log(err, cloneDeep(product.images[i]));
                                // logger.log(err, cloneDeep(product.images[i]));
                            }

                            newImagesAdded = true;

                            if (imageData.uploadKey) {
                                try {
                                    await api.backend.post("/upload/delete", {
                                        body: {
                                            uploadKey: imageData.uploadKey,
                                        },
                                    });
                                }
                                catch (err) {
                                    // console.log(err, cloneDeep(product.images[i]));
                                    // logger.log(err, cloneDeep(product.images[i]));
                                }
                            }

                            try {
                                if (s3key) {
                                    try {
                                        await preloadImages([
                                            s3url(s3key, 1000),
                                        ]).then((images) => {
                                            const image = images[0];
                                            // console.log({ ...image })
                                            image.src = s3url(s3key);
                                            image.key = s3key;
                                            image.bgRemoved = bgRemoved;
                                            // console.log({ ...image })
                                            if (
                                                typeof prodData.images
                                                    === "string"
                                            ) {
                                                const parsed = JSON.parse(
                                                    prodData.images,
                                                );
                                                parsed[inx] = image;
                                                prodData.images = JSON
                                                    .stringify(parsed);
                                            }
                                            else {
                                                prodData.images[inx] = image;
                                            }
                                        });
                                    }
                                    catch (err) {
                                        console.log(err);
                                        delete prodData.images[inx];
                                    }
                                }
                            }
                            catch (err) {
                                console.log(err);
                                // console.log(err, cloneDeep(product.images[i]));
                                // logger.log(err, cloneDeep(product.images[i]));
                                prodData.images[i].src = "";
                                prodData.images[i].key = "";
                            }
                        }

                        // console.log("end image", imageData.key || imageData.src)
                        resolve();
                    }),
                );
            }

            await Promise.allSettled(promises);

            delete prodData.image;

            prodData.images = JSON.stringify(prodData.images);
        }

        /// CREATE/UPDATE PRODUCT

        if (editMode) {
            // don't need to wait here
            delete prodData.retailer;
            delete prodData.sizes;
            api.product.update(prodId, prodData);
        }
        else {
            prodId = await api.product.create(prodData).then((r) => r.id);
        }

        /// CREATE TAGS
        // if (styles.length > 0 || designers.length > 0) {
        //     const allTags = await api.backend.post("/tag/prepare", {
        //         body: {
        //             ProductStyle: styles,
        //             Designer: designers,
        //         },
        //     });

        //     styleIds = allTags.ProductStyle.map((t) => t.id);
        //     designerIds = allTags.Designer.map((t) => t.id);
        // }

        /// DELETE REMOVED TAGS

        // if (editMode) {
        //     deletedProductStyles &&
        //         deletedProductStyles.forEach(
        //             (s) =>
        //                 s.id.indexOf("tmp_") === -1 &&
        //                 api.productProductStyle.remove({
        //                     productStyleId: { _eq: s.id },
        //                     productId: { _eq: prodId },
        //                 })
        //         );
        //     deletedDesigners &&
        //         deletedDesigners.forEach(
        //             (d) =>
        //                 d.id.indexOf("tmp_") === -1 &&
        //                 api.productDesigner.remove({
        //                     designerId: { _eq: d.id },
        //                     productId: { _eq: prodId },
        //                 })
        //         );
        // }

        /// ATTACH TAGS

        // if (styleIds.length > 0) {
        //     // we're not using it anymore
        //     api.productProductStyle.create(
        //         styleIds.map((productStyleId) => ({
        //             productStyleId,
        //             productId: prodId,
        //         })),
        //         "returning { productStyleId }",
        //         {
        //             constraint: "Product_ProductStyle_pkey",
        //             update_columns: [],
        //         }
        //     );
        // }

        // if (designerIds.length > 0) {
        //     await api.productDesigner.create(
        //         designerIds.map((designerId) => ({
        //             designerId,
        //             productId: prodId,
        //         })),
        //         "returning { designerId }",
        //         {
        //             constraint: "Product_Designer_pkey",
        //             update_columns: [],
        //         }
        //     );
        // }

        hub.dispatch("look", "product-created", { id: prodId });
        return prodId;
    }
    catch (err) {
        hub.dispatch("error", "product-create", err);
        // return Promise.reject(err);
        return prodId;
    }
}

export function remove(id) {
    store.dispatch(ui.deleting.start(id));

    return api.product
        .remove(id)
        .then(() => {
            hub.dispatch("look", "product-removed", { id });
        })
        .finally(() => {
            store.dispatch(ui.deleting.stop(id));
        })
        .catch((err) => {
            hub.dispatch("error", "product-remove", err);
        });
}
