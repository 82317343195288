export default function(imgNode) {
    const w = imgNode.naturalWidth || imgNode.width;
    const h = imgNode.naturalHeight || imgNode.height;
    return {
        src: imgNode.src,
        width: w,
        height: h,
        vertical: h > w,
        horizontal: h < w,
        square: h === w,
        ratio: w / h,
    };
}
