import getClickDomain from "common/src/lib/url/clickDomain";
import user from "common/src/user";

function isEncoded(url) {
    let encoded = 0;
    while (url !== decodeURIComponent(url)) {
        url = decodeURIComponent(url);
        encoded++;
    }
    return encoded;
}

function getProductUrl(p, referenceUserId = null, source = null) {
    let refid = null;

    if (referenceUserId !== false) {
        refid = referenceUserId;
        if (!refid) {
            if (!user.isOnly("User")) {
                refid = user.id();
            }
        }
    }

    const clickDomain = getClickDomain();
    const uid = user.id();

    let url = p.url;
    let encoded = isEncoded(url);
    let click = `https://${clickDomain}?`;

    if (url.indexOf("{") !== -1 || url.indexOf("[") !== -1) {
        url = encodeURIComponent(url);
        encoded++;
    }

    if (refid) {
        click += `refid=${refid}&`;
    }
    if (source && source.type && source.id) {
        click += `srctype=${source.type}&srcid=${source.id}&`;
    }
    click += `cpid=${p.id}&uid=${uid}&encoded=${encoded}&url=${url}`;
    return click;
}

export default getProductUrl;
