import {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import ImageSliderIncicator from "./ImageSliderIndicator";

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";
import async from "common/src/lib/js/async";

import { ReactComponent as IconLt } from "common/src/svg/lt.svg";
import { ReactComponent as IconGt } from "common/src/svg/gt.svg";
import useDictRef from "common/src/hooks/useDictRef";

const MOUSE_DRAG_THRESHOLD = 50;

function ImageSlider({
    initialCurrent = 0,
    indicator,
    className,
    style,
    images,
    onChange,
}) {
    const ref = useRef();
    const scrollRef = useRef();
    const total = useMemo(() => images.length, [images]);
    const [current, setCurrent] = useState(() => initialCurrent || 0);
    const [size, setSize] = useState({ width: 0, height: 0 });
    const cls = useMemo(
        () => ["image-slider", className || ""].join(" "),
        [className]
    );

    const showIndicator = useMemo(
        () => (indicator === "auto" ? total > 1 : indicator !== false),
        [indicator, total]
    );

    const indicatorProps = useMemo(
        () => (typeof indicator === "object" ? indicator : {}),
        [indicator]
    );

    const pages = useMemo(
        () =>
            images.map((i) => ({
                src: i,
                style: {
                    backgroundImage: `url(${i})`,
                    ...size,
                },
            })),
        [images, size]
    );

    const pagesStyle = useMemo(
        () => ({ height: size.height, width: total * size.width }),
        [size, total]
    );

    const next = useCallback(() => {
        if (current >= total - 1 || size.width === 0) {
            return;
        }
        const sl = (current + 1) * size.width;
        scrollRef.current.scrollTo({ left: sl, behavior: "smooth" });
    }, [onChange, current, total, size]);

    const prev = useCallback(() => {
        if (current === 0 || size.width === 0) {
            return;
        }
        const sl = (current - 1) * size.width;
        scrollRef.current.scrollTo({ left: sl, behavior: "smooth" });
    }, [onChange, current, total, size]);

    const dict = useDictRef({ width: size.width, next, prev });

    const onScroll = useCallback(
        (e) => {
            const w = size.width;
            const sl = e.target.scrollLeft;
            if (w === 0) {
                return;
            }
            const current = Math.round(sl / w);
            setCurrent(current);
            onChange && onChange(current, total);
        },
        [total, size, onChange]
    );

    const onPrevClick = useSwallowEventCallback(() => {
        dict.prev();
    }, []);

    const onNextClick = useSwallowEventCallback(() => {
        dict.next();
    }, []);

    const onResize = useCallback(() => {
        const { width, height } = ref.current.getBoundingClientRect();
        setSize({ width, height });
        if (dict.width === 0) {
            async(() => scrollRef.current.scrollTo({ left: 0 }));
        }
    }, []);

    const onMouseUp = useCallback(() => {
        document.body.removeEventListener("mouseup", onMouseUp);
        document.body.removeEventListener("mousemove", onMouseMove);
    }, []);

    const onMouseMove = useCallback((e) => {
        const diff = e.clientX - dict.startX;
        if (diff < -MOUSE_DRAG_THRESHOLD) {
            dict.next();
            onMouseUp();
        } else if (diff > MOUSE_DRAG_THRESHOLD) {
            dict.prev();
            onMouseUp();
        }
    }, []);

    const onMouseDown = useCallback((e) => {
        e.preventDefault();
        dict.startX = e.clientX;
        document.body.addEventListener("mouseup", onMouseUp);
        document.body.addEventListener("mousemove", onMouseMove);
    }, []);

    useEffect(() => {
        window.addEventListener("resize", onResize);
        async(onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    return (
        <Fragment>
            <div className={cls} style={style} ref={ref}>
                {current > 0 && (
                    <a
                        href="/#"
                        className="image-slider-control image-slider-control-left"
                        onClick={onPrevClick}
                        children={<IconLt />}
                    />
                )}
                <div
                    className="image-slider-scroller"
                    onScroll={onScroll}
                    ref={scrollRef}
                    onMouseDown={onMouseDown}>
                    <div className="image-slider-pages" style={pagesStyle}>
                        {pages.map((p) => (
                            <div
                                className="image-slider-page"
                                key={p.src}
                                style={p.style}
                            />
                        ))}
                    </div>
                </div>
                {current < total - 1 && (
                    <a
                        href="/#"
                        className="image-slider-control image-slider-control-right"
                        onClick={onNextClick}
                        children={<IconGt />}
                    />
                )}
            </div>
            {showIndicator && (
                <ImageSliderIncicator
                    current={current}
                    total={total}
                    {...indicatorProps}
                />
            )}
        </Fragment>
    );
}

export default ImageSlider;
