import { Fragment, useMemo, useState } from "react";

import Loader from "common/src/components/Loader";
import ImageSliderIncicator from "common/src/refactor/components/image-slider/ImageSliderIndicator";

// import useImageLoading from "common/src/refactor/hooks/useImageLoading";
import useImage from "common/src/refactor/hooks/useImage";

function Image({ product, indicator = false }) {
    const total = useMemo(
        () => {
            return (product.cdnImages || product.images || []).length;
        },
        [],
    );

    const showIndicator = useMemo(
        () => (indicator === "auto" ? total > 1 : indicator !== false),
        [ indicator, total ],
    );

    const indicatorProps = useMemo(
        () => {
            return typeof indicator === "object" ? indicator : {};
        },
        [ indicator ],
    );

    const srcs = useMemo(
        () => {
            const srcs = [];
            if (product.cdn_images && product.cdn_images.length > 0) {
                const img = product.cdn_images[0];
                srcs.push(typeof img === "string" ? img : img.src);
            }
            if (product.images && product.images.length > 0) {
                const img = product.images[0];
                srcs.push(typeof img === "string" ? img : img.src);
            }
            return srcs;
        },
        [ product ],
    );

    const { loading, src, isFallback } = useImage(srcs);

    // const image = useMemo(() => {
    //     let img = null;
    //     if (product.cdn_images && product.cdn_images.length > 0) {
    //         img = product.cdn_images[0];
    //     }
    //     if (product.images && product.images.length > 0) {
    //         img = product.images[0];
    //     }
    //     if (img && typeof img !== "string") {
    //         img = img.src;
    //     }
    //     return img;
    // }, [product]);

    const style = useMemo(
        () => {
            const style = {};
            if (src) {
                style.backgroundImage = `url(${src})`;
            }
            if (isFallback) {
                style.backgroundSize = "15%";
                style.opacity = 0.5;
            }
            return style;
        },
        [ src, isFallback ],
    );

    // const loading = useImageLoading(image);

    return (
        <Fragment>
            <div
                className="catalogue-product-card-image"
                style={style}
                children={loading ? <Loader /> : null} />
            {showIndicator && (
                <ImageSliderIncicator total={total} {...indicatorProps} />
            )}
        </Fragment>
    );
}

export default Image;
