import api from "common/src/api";
import entities from "common/src/api/hasura/entities";

//import "common/src/api/entity/tags"
//import "common/src/api/entity/notifications"

const User = `
    id
    givenName
    familyName
    avatar
    handle
    email
    phone
    groups
    bio
    details
    enabled
    userTerms
    friTerms
    createdAt
    instagram
`;

export const Consultation = `
    id
    indexNo
    title
    contacts
    description
    due
    sizes
    status
    subStatus
    hidden
    createdAt
    updatedAt
    customerId
    friId
    deletionRequest
    customer {
        id
        givenName
        familyName
        phone
        email
        avatar
        contacts {
            type
            value
            normalized
        }
    }
    lastMessage: chatMessages(limit: 1, order_by: { createdAt: desc }) {
        ${entities.Chat_Message.list}
    }
    fri { ${User} }
    looks(where: { deleted: { _eq: false }}) { ${entities.Look.list} }
    images(order_by: { position: asc }) {
        id
        comment
        image
    }
    products {
        id
        sizes
        status
        purchasedAt
        fri { ${User} }
        product {
            id
            createdAt
            description
            salePrice
            price
            currency
            image
            images
            url
            availability
            sizes
            retailer
            productReferenceId
            catalogueProductReference {
                id
                productId
                region
            }
        }
    }
    paymentDetailsId
    paymentDetails {
        paymentDetails
        billingAddress
        shippingAddress
    }
`;
// catalogueId
//             productStyles { productStyle { ${entities.ProductStyle.list} } }
//             designers { designer { ${entities.Designer.list} } }

export const ConsultationDraft = `
    createdAt
    data
    id
    userId
`;
const User_RecentlyDeleted = `
    userId
    deletedAt
    consultationId
    lookId
    moodboardId
`;

const Moodboard = `
    id 
    friId
    name
    image
    public
    pseTemplate
    createdAt
    deleted
    description
    productReferences_aggregate {
        aggregate {
            count
        }
    }
    productReferences(limit: 5, order_by: {position: asc}) {
        catalogueProductReference {
            productId
            images
            region
        }
    }
    hideMoodboards {
        userId
    }
`;

// const Moodboard_CatalogueProduct = `
//     id
//     userId
//     catalogueProductId
//     createdAt
//     moodboardId
//     position
// `;

const Moodboard_ProductReference = `
    id
    moodboardId
    userId
    productReferenceId
    createdAt
    position
    catalogueProductReference {
        productId
        images
        region
    }
`;

const Moodboard_Share = `
    id
    moodboardId
    userId
    createdAt
`;

/*const Moodboard_Product = `
    id
    moodboardId
    name
    image
    url
    retailer
    productWebId
    brand
    price
    salePrice
    currency
`;*/

const Look = `
    ${entities.Look.list}
    published
    submitted
    submissionStage
`;

const Banner = `
    id
    text
    startDate
    endDate
    active
`;

const User_Banner = `
    active
    bannerId
    userId
    countClosed
    id
`;

entities.User.list = User;
entities.Consultation.list = Consultation;

Object.assign(entities, {
    Look: { list: Look, create: true, update: true, remove: true },
    Product: {
        list: entities.Product.list,
        create: true,
        update: true,
        remove: true,
    },
    Moodboard: { list: Moodboard, create: true, update: true, remove: true },
    // Moodboard_CatalogueProduct: {
    //     list: Moodboard_CatalogueProduct,
    //     create: true,
    //     update: true,
    //     remove: true,
    // },
    Moodboard_ProductReference: {
        list: Moodboard_ProductReference,
        create: true,
        update: true,
        remove: true,
    },
    Moodboard_Share: { list: Moodboard_Share, create: true, remove: true },
    User_HideMoodboard: { create: true },
    ConsultationDraft: {
        list: ConsultationDraft,
        create: true,
        update: true,
        remove: true,
    },
    User_RecentlyDeleted: { list: User_RecentlyDeleted },
    Banner: { list: Banner },
    User_Banner: { list: User_Banner, update: true, create: true },
    //Moodboard_Product: { list: Moodboard_Product, create: true, update: true, remove: true },
});

export const init = () => {
    api.init();
};

export default api;
