import { useMemo } from "react";

import Button from "common/src/refactor/components/button/Button";
import { ReactComponent as IconExport } from "common/src/svg/open_in.svg";

import useCatalogueProductUrl from "common/src/refactor/hooks/useCatalogueProductUrl";

function Retailer({ product, buttonSize = "small" }) {
    const productUrl = useCatalogueProductUrl(product);

    const cls = useMemo(() => {
        const cls = [];
        if (product.retailer?.length > 8) {
            cls.push("len-8");
        }
        if (product.retailer?.length > 16) {
            cls.push("len-16");
        }
        return cls; //.join(" ");
    }, [product.retailer]);

    return (
        <Button
            className={cls}
            type="primary"
            size={buttonSize}
            Component="a"
            target="_blank"
            rel="noreferrer"
            iconPosition="after"
            href={productUrl}
            text={product.retailer}
            Icon={IconExport}
        />
    );
}

export default Retailer;
