import { useCallback } from "react";
import { useSelector } from "react-redux";

import Button from "common/src/refactor/components/button/Button";
import Page from "common/src/refactor/components/Page";

import ProfileAnalytics from "common/src/refactor/components/profile/Analytics";
import ProfileAvatar from "common/src/refactor/components/profile/Avatar";
import ProfileBio from "common/src/refactor/components/profile/Bio";
import ProfileDetails from "common/src/refactor/components/profile/Details";

import EditAvatarDialog from "common/src/refactor/components/profile/EditAvatarDialog";
import EditBankDetailsDialog from "common/src/refactor/components/profile/EditBankDetailsDialog";
import EditUserDetailsDialog from "common/src/refactor/components/profile/EditDetailsDialog";

import userSelector from "common/src/selectors/user/current";

function PageProfile() {
    const user = useSelector(userSelector);
    const dashboardType = useSelector(s =>
        s.user.current.details?.dashboardType || "commissions"
    );

    const onEditAvatarClick = useCallback(
        () => {
            EditAvatarDialog.show();
        },
        [],
    );

    const onEditDetailsClick = useCallback(
        () => {
            EditUserDetailsDialog.show();
        },
        [],
    );

    const onEditBankDetailsClick = useCallback(
        () => {
            EditBankDetailsDialog.show();
        },
        [],
    );

    if (!user) {
        <Page loading />;
    }

    return (
        <Page className="page-profile">
            <h2 className="profile-title">Your profile</h2>
            <div className="profile-content">
                <div className="page-profile-details">
                    <ProfileDetails />
                    <div className="page-profile-actions">
                        <Button
                            type="primary"
                            text="Edit"
                            onClick={onEditDetailsClick} />
                    </div>
                </div>
                <div className="page-profile-avatar">
                    <ProfileAvatar />
                    <ProfileBio />
                    <div className="page-profile-actions">
                        <Button
                            type="primary"
                            text="Edit"
                            onClick={onEditAvatarClick} />
                    </div>
                </div>
                <div className="page-profile-analytics">
                    <ProfileAnalytics />
                    {dashboardType === "commissions" && (
                        <div className="page-profile-actions">
                            <Button
                                onClick={onEditBankDetailsClick}
                                type="primary"
                                text="Edit bank details" />
                        </div>
                    )}
                </div>
            </div>
            <EditAvatarDialog />
            <EditUserDetailsDialog />
        </Page>
    );
}

export default PageProfile;
