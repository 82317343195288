import api from "app/api";
import { createProductReference } from "common/src/actions/catalogueLocal";
import hub from "common/src/hub";
import Chat from "common/src/lib/chat/Chat";
import s3url from "common/src/lib/image/s3url";
import upload from "common/src/lib/image/upload";
import user from "common/src/user";

export const addProducts = async (id, products, metadatas = {}) => {
    const addedIds = products.map((p) => p.id);
    let position = Math.floor((new Date().getTime()) / 1000);

    const promises = products.map(async (product) => {
        const productReferenceId = await createProductReference(
            product,
            metadatas[product.id] || null,
        );
        const where = {
            moodboardId: { _eq: id },
            productReferenceId: { _eq: productReferenceId },
        };
        const ex = await api.moodboardProductReference
            .list({ where }, "id productReferenceId")
            .then((list) => list.map((p) => p.productReferenceId));

        if (ex.indexOf(productReferenceId) !== -1) {
            return;
        }

        await api.moodboardProductReference.create({
            moodboardId: id,
            productReferenceId: productReferenceId,
            userId: user.id(),
            position,
        });

        position += 5;
    });

    promises.push(
        api.moodboard.update(id, {
            contentUpdatedAt: new Date().toISOString(),
        }),
    );

    await Promise.all(promises);

    hub.dispatch("moodboard", "products-added", { id, productIds: addedIds });
};

export const addProduct = async (id, product) => {
    await addProducts(id, [ product ]);
};

export const removeProducts = async (id, productIds) => {
    const where = {
        moodboardId: { _eq: id },
        catalogueProductReference: { productId: { _in: productIds } },
        userId: { _eq: user.id() },
    };
    await api.moodboardProductReference.remove(where);
    await api.moodboard.update(id, {
        contentUpdatedAt: new Date().toISOString(),
    });

    hub.dispatch("moodboard", "products-removed", { id, productIds });
};

const saveImage = async (image, cropCoords) => {
    if (!image) {
        return null;
    }
    if (!image.localId) {
        return image;
    }
    const w = image.width;
    const h = image.height;
    const uploadKey = await upload(image.file || image);

    const payload = {
        uploadKey,
        contentType: image.mime,
        name: image.name,
    };

    if (cropCoords) {
        payload.crop = cropCoords;
    }

    // console.log(payload);

    const imgResponse = await api.backend.post("/upload/moodboard", {
        body: payload,
    });
    const key = imgResponse.key;

    return {
        key,
        src: s3url(key),
        width: w,
        height: h,
        vertical: h > w,
        horizontal: h < w,
        square: h === w,
    };
};

export const create = async (
    name,
    image,
    cropCoords,
    isPublic = false,
    description,
    pseTemplate,
) => {
    image = await saveImage(image, cropCoords);

    const data = {
        name,
        image: image ? JSON.stringify(image) : null,
        friId: user.id(),
        public: isPublic,
        description: description || null,
        contentUpdatedAt: new Date().toISOString(),
    };

    const res = await api.moodboard.create(data);

    if (user.is("Admin")) {
        await api.moodboard.update(res.id, {
            pseTemplate,
        });
    }

    hub.dispatch("moodboard", "created", { id: res.id, name });

    return res;
};

export const update = async (
    id,
    name,
    image,
    cropCoords,
    isPublic = false,
    description,
) => {
    image = await saveImage(image, cropCoords);

    const data = {
        name,
        image: image ? JSON.stringify(image) : null,
        public: isPublic,
        description: description || null,
        contentUpdatedAt: new Date().toISOString(),
    };

    await api.moodboard.update(id, data);

    hub.dispatch("moodboard", "updated", { id, name });
};

export const updatePseTemplate = async (id, pseTemplate = false) => {
    if (user.is("Admin")) {
        await api.moodboard.update(id, {
            pseTemplate,
        });
        hub.dispatch("moodboard", "updated", { id });
    }
};

export const remove = async (id) => {
    await api.moodboard.update(id, {
        deleted: true,
        deletedAt: new Date().toISOString(),
    });

    hub.dispatch("moodboard", "removed", { id });
};

export const generatePublicLink = async (moodboardId, currency) => {
    const { link } = await api.backend.post("/moodboard/generateLink", {
        body: {
            moodboardId,
            currency,
        },
    });

    return link;
};

export const moveProducts = async (
    fromId,
    toId,
    productIds,
    action = "copy",
) => {
    const userId = user.id();
    const promises = [];

    const fromReferences = await api.moodboardProductReference(
        {
            where: {
                moodboardId: { _eq: fromId },
                catalogueProductReference: { productId: { _in: productIds } },
            },
        },
        "productReferenceId",
    );

    const toReferences = await api.moodboardProductReference(
        {
            where: {
                moodboardId: { _eq: toId },
                catalogueProductReference: { productId: { _in: productIds } },
            },
        },
        "productReferenceId",
    );

    let position = Math.floor((new Date().getTime()) / 1000);
    for (const fromRef of fromReferences) {
        const ex = toReferences.find(
            (ref) => ref.productReferenceId === fromRef.productReferenceId,
        );

        if (ex) {
            continue;
        }

        promises.push(
            api.moodboardProductReference.create({
                moodboardId: toId,
                productReferenceId: fromRef.productReferenceId,
                userId,
                position,
            }),
        );
        position++;
    }

    if (action === "move") {
        for (const fromRef of fromReferences) {
            promises.push(
                api.moodboardProductReference.remove({
                    moodboardId: fromId,
                    productReferenceId: fromRef.productReferenceId,
                    userId,
                }),
            );
        }
    }

    promises.push(
        api.moodboard.update(toId, {
            contentUpdatedAt: new Date().toISOString(),
        }),
    );
    await Promise.all(promises);

    hub.dispatch("moodboard", "products-added", { id: toId, productIds });
};

export const shareMoodboard = async (moodboardId, toUserId) => {};

export const sendMoodboard = async (moodboardId, toUserId) => {
    const chat = new Chat({ detached: true, currentUserRole: "shopper" });
    const contact = await api.user.get(toUserId);
    const moodboard = await api.moodboard.get(moodboardId);
    await chat.setContact(contact);

    chat.addAttachment("moodboard", moodboard, moodboard.id);
    await chat.send();
};

export const hideMoodboard = async (id) => {
    try {
        await api.userHideMoodboard.create({
            moodboardId: id,
            userId: user.id(),
        });
    }
    catch (err) {
        console.error(err);
    }

    hub.dispatch("moodboard", "removed", { id });
};

export const rearrange = async (moodboardId, sourceProduct, targetProduct) => {
    await api.backend.post("/moodboard/rearrange", {
        body: {
            moodboardId,
            productId: sourceProduct.id,
            targetProductId: targetProduct.id,
        },
    });

    await api.moodboard.update(moodboardId, {
        contentUpdatedAt: new Date().toISOString(),
    });

    hub.dispatch("moodboard", "rearrange", { id: moodboardId });
};

export const copy = async (moodboardId) => {
    const res = await api.backend.post("/moodboard/copy", {
        body: {
            moodboardId,
        },
    });

    hub.dispatch("moodboard", "created", { id: res.id });
};
