import api from "app/api";
import hub from "common/src/hub";
import user from "common/src/user";
import { createProductReference } from "./catalogueLocal";
import { referencedProductsLoader } from "./productReference";

export const create = async (name) => {
    await api.moodboard.create({ name, friId: user.id() });
    hub.dispatch("moodboard", "created", name);
};

export const update = async (id, name) => {
    await api.moodboard.update(id, { name });
    hub.dispatch("moodboard", "updated", id);
};

export const remove = async (id) => {
    await api.moodboard.remove(id);
    hub.dispatch("moodboard", "removed", id);
};

export const addProduct = async (id, product, metadata = null) => {
    const refId = await createProductReference(product, metadata);

    const ex = await api.moodboardProductReference.list({
        where: {
            moodboardId: { _eq: id },
            productReferenceId: { _eq: refId },
            userId: { _eq: user.id() },
        },
    });
    if (ex.length === 0) {
        await api.moodboardProductReference.create({
            moodboardId: id,
            productReferenceId: refId,
            userId: user.id(),
        });
    }

    hub.dispatch("moodboard", "product-added", id);
};

export const removeProduct = async (mid, pid) => {
    await api.moodboardProductReference.remove({
        moodboardId: { _eq: mid },
        catalogueProductReference: { productId: { _eq: pid } },
    });
    hub.dispatch("moodboard", "product-removed", mid);
};

export const loadMoodboards = async (params) => {
    const { perPage = 40, page = 0, query = "", mine = false, type } = params;
    const where = {
        _and: [
            { deleted: { _eq: false } },
            {
                _not: {
                    hideMoodboards: {
                        userId: { _eq: user.id() },
                    },
                },
            },
        ],
    };

    if (type) {
        switch (type) {
            case "my":
                where._and.push({ friId: { _eq: user.id() } });
                break;
            case "tf":
                where._and.push({ pseTemplate: { _eq: true } });
            default:
                break;
        }
    }
    else if (mine) {
        where._and.push({ friId: { _eq: user.id() } });
    }

    if (query) {
        const moodboardIds = await api.moodboard
            .list({ where }, "id")
            .then((rows) => rows.map((row) => row.id));

        if (moodboardIds.length > 0) {
            const { data: productIds } = await referencedProductsLoader({
                moodboardId: moodboardIds,
                query,
                returnIds: true,
            });
            if (productIds.length > 0) {
                where._and.push({
                    _or: [
                        { name: { _ilike: `%${query}%` } },
                        {
                            productReferences: {
                                catalogueProductReference: {
                                    productId: { _in: productIds },
                                },
                            },
                        },
                    ],
                });
            }
        }
    }

    const order = { createdAt: "desc" };
    const limit = perPage;
    const offset = page * perPage;
    return await api.moodboard.list(
        { where, order, offset, limit },
        null,
        true,
    );
};
