import { useOn } from "@kuindji/observable-react";
import {
    catalogueLoader,
    getProductsBySet,
} from "common/src/actions/catalogue";
import useDictRef from "common/src/hooks/useDictRef";
import useUpdateEffect from "common/src/hooks/useUpdateEffect";
import { allApiKeys } from "common/src/refactor/lib/FilterApi";
import { sets } from "common/src/store/catalogue";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useProductCatalogueMessages from "./useProductCatalogueMessages";

const MAX_RETRIES = 3;
const REQUEST_TIMEOUT = 7000;

function filters2request(filters) {
    const request = {};

    if ("gender" in filters && filters["gender"]) {
        request["gender"] = filters["gender"];
    }
    if ("sale" in filters && filters["sale"]) {
        request["sale"] = filters["sale"];
    }

    if ("region" in filters && filters["region"]) {
        request["region"] = filters["region"].toLowerCase();
        const region = filters["region"].toLowerCase();
        if (region === "us") {
            request["convert_to_currency"] = "USD";
        }
        else if (region === "eu") {
            request["convert_to_currency"] = "EUR";
        }
        else if (region === "gb") {
            request["convert_to_currency"] = "GBP";
        }
        else if (region === "row") {
            request["convert_to_currency"] = "USD";
        }
    }

    if ("order_by" in filters && filters["order_by"]) {
        request["order_by"] = filters["order_by"];
    }

    if ("query" in filters && filters["query"]) {
        request["query"] = filters["query"];
    }
    if ("retailer" in filters && filters["retailer"]) {
        request["retailer"] = filters["retailer"];
    }
    if ("designer" in filters && filters["designer"]) {
        request["designer"] = filters["designer"];
    }
    if ("price" in filters && filters["price"]) {
        let price = filters["price"];
        if (price.indexOf("/") === -1) {
            price = request["convert_to_currency"].toLowerCase() + "/" + price;
        }
        request["price"] = price;
    }
    if ("category" in filters && filters["category"]) {
        request["category"] = filters["category"];
    }

    return request;
}

function filter2deps(data) {
    return Object.keys(data)
        .sort()
        .map((k) => data[k])
        .join("-");
}

function useProductCatalogue({
    filters,
    setName,
    clearOnUnmount = true,
    useApplied = false,
    enabled = true,
    perPage = 36,
    callbacks,
}) {
    const dispatch = useDispatch();
    const puids = useSelector((s) => s.catalogue.sets.products[setName]);
    const geo = useSelector((s) => s.user.geo || {});
    const currency = geo.currency;
    const originalCurrency = geo.original || false;
    const [ filterDeps, setFilterDeps ] = useState(() =>
        filter2deps(
            useApplied
                ? filters.getApplied(allApiKeys)
                : filters.get(allApiKeys),
        )
    );
    const {
        loading = true,
        loaded = false,
        page = 0,
        hasMore,
    } = useSelector((s) => s.catalogue.ui.products.misc[setName] || {});
    const [ products, setProducts ] = useState(() => getProductsBySet(setName));
    const [ loadingTimeout, setLoadingTimeout ] = useState(null);
    const [ retries, setRetries ] = useState(0);
    const [ isError, setIsError ] = useState(false);
    const [ takingTooLong, setTakingTooLong ] = useState(false);

    const ref = useDictRef({
        loading,
        clearOnUnmount,
        setName,
        filters,
        page,
        perPage,
        originalCurrency,
        currency,
        callbacks,
        loadingTimeout,
        retries,
        enabled,
    });

    const onFiltersChange = useCallback(() => {
        setFilterDeps(
            filter2deps(
                useApplied
                    ? ref.filters.getApplied(allApiKeys)
                    : ref.filters.get(allApiKeys),
            ),
        );
    }, [ useApplied ]);

    useOn(filters, useApplied ? "apply" : "change", onFiltersChange);

    const load = useMemo(() => {
        if (!ref.enabled) {
            return () => {};
        }

        let called = 0;
        setTakingTooLong(false);
        setLoadingTimeout(null);
        setRetries(0);
        setIsError(false);

        if (ref.errorReloadTmt) {
            clearTimeout(ref.errorReloadTmt);
            ref.errorReloadTmt = null;
        }

        return async ({
            append = false,
            page = 0,
            ignoreCalled = false,
        } = {}) => {
            if (called > 0 && !ignoreCalled) {
                return;
            }
            called++;

            ref.errorReloadTmt = null;

            setLoadingTimeout(
                setTimeout(() => {
                    setTakingTooLong(true);
                }, REQUEST_TIMEOUT),
            );

            try {
                await catalogueLoader(
                    {
                        ...filters2request(
                            useApplied
                                ? ref.filters.getApplied(allApiKeys)
                                : ref.filters.get(allApiKeys),
                        ),
                        append,
                        page,
                        perPage: ref.perPage,
                        setName: ref.setName,
                    },
                    ref.callbacks,
                );

                setTakingTooLong(false);
                setLoadingTimeout(null);
                setRetries(0);
                setIsError(false);
                ref.loadingTimeout && clearTimeout(ref.loadingTimeout);
            }
            catch (err) {
                console.log(err);
                setLoadingTimeout(null);
                ref.loadingTimeout && clearTimeout(ref.loadingTimeout);

                if (ref.retries < MAX_RETRIES) {
                    setRetries((prev) => prev + 1);
                    called--;
                    setTakingTooLong(true);
                    ref.errorReloadTmt = setTimeout(() => {
                        load({ append, page, ignoreCalled });
                    }, 1000);
                }
                else {
                    setIsError(true);
                    setTakingTooLong(false);
                    setRetries(0);
                }
            }
        };
    }, [ filterDeps, useApplied, enabled ]);

    ref.load = load;

    // useHub("catalogue-loader", "productWebId-set", load);

    const reload = useCallback(() => {
        load();
    }, []);

    const loadMore = useCallback(() => {
        if (ref.loading) {
            return;
        }
        load({ append: true, page: ref.page + 1, ignoreCalled: true });
    }, [ load ]);

    const messages = useProductCatalogueMessages({
        filters,
        products,
        setName,
        takingTooLong,
        isError,
        retries,
    });

    useUpdateEffect(() => setProducts(getProductsBySet(ref.setName)), [
        puids,
    ]);

    useUpdateEffect(() => {
        if (ref.loadTimeout) {
            clearTimeout(ref.loadTimeout);
            ref.loadTimeout = null;
        }
        ref.loadTimeout = setTimeout(() => {
            ref.loadTimeout = null;
            load();
        });
    }, [ filterDeps ]);

    useEffect(() => {
        load();
        return () => {
            if (ref.clearOnUnmount) {
                dispatch(sets.products.unset(ref.setName));
            }
        };
    }, []);

    useUpdateEffect(() => {
        if (enabled) {
            ref.load();
        }
    }, [ enabled ]);

    return {
        loading,
        products,
        reload,
        loadMore,
        page,
        hasMore,
        loaded,
        messages,
        takingTooLong,
        isError,
        retries,
    };
}

export default useProductCatalogue;
