import { useCallback, useContext, useMemo, useState } from "react";
import { batch, useDispatch } from "react-redux";

import Dnd from "common/src/refactor/components/Dnd";
import ProductCard from "common/src/refactor/components/look/Product";

import { ReactComponent as IconDelete } from "common/src/svg/close.svg";
import { ReactComponent as IconDrag } from "common/src/svg/drag.svg";
import { ReactComponent as IconGt } from "common/src/svg/gt.svg";
import { ReactComponent as IconLt } from "common/src/svg/lt.svg";

import useDictRef from "common/src/hooks/useDictRef";
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";
import useUpdateEffect from "common/src/hooks/useUpdateEffect";
import addListener from "common/src/lib/dom/addListener";
import removeListener from "common/src/lib/dom/removeListener";
import async from "common/src/lib/js/async";
import getProductLayout from "common/src/lib/look/getProductLayout";
import adjustPosition from "common/src/lib/look/layout/adjustPosition";
import { getBackgroundSize } from "common/src/lib/look/layout2style";
import useKey from "common/src/refactor/hooks/useKey";
import { data as lookData } from "common/src/store/look/editor";
import { data } from "common/src/store/look/editor";
import { useSelector } from "react-redux";
import LookBuilderContext from "./LookBuilderContext";

function LookCardProductActions({ product, onLtClick, onGtClick, index }) {
    const dispatch = useDispatch();
    const builder = useContext(LookBuilderContext);
    const totalImages = useMemo(() => product.images?.length || 0, [ product ]);
    const activeSlot = useKey("activeSlot", builder);
    const editMode = activeSlot === index;
    const look = useSelector(s => s.lookEditor.look);
    const ref = useDictRef({ look });

    const onEditClick = useSwallowEventCallback(
        () => {
            builder.set("activeSlot", index);
        },
        [ builder, product, index ],
    );

    const onDeleteClick = useSwallowEventCallback(
        () => {
            batch(() => {
                dispatch(data.products.removeFromLayout(product.id));
                dispatch(data.products.remove(product.id));
            });
        },
        [ builder, product ],
    );

    const onProductMove = useCallback(
        (to, what) => {
            const pid = what.id,
                fromIndex = what.slotIndex,
                toInx = to.slotIndex,
                look = ref.look,
                product = look.products.find(p => p.id === pid),
                mode = look.productMode || "multiple";

            if (mode === "multiple") {
                dispatch(data.products.setToLayout({ id: pid, toInx }));
            }
            else {
                const src = product.images[fromIndex].src;
                dispatch(data.products.moveImage({ id: pid, src, toInx }));
            }
        },
        // eslint-disable-next-line
        [],
    );

    const { Cmp, attrs } = useMemo(
        () => {
            return {
                Cmp: Dnd,
                attrs: {
                    droppable: {
                        cardSelector: ".look-card-product",
                        drop: onProductMove,
                        // drop: (to, what) => this.onProductMove(what.id, what.index, to.index),
                        data: {
                            id: product.id,
                            slotIndex: index,
                        },
                    },
                },
            };
        },
        [ product, index, onProductMove ],
    );

    const moveAttrs = useMemo(
        () => {
            return {
                draggable: {
                    imageElId: `look-card-product-${product.id}`,
                    id: product.id,
                    index: index,
                    origin: "look-card",
                    data: {
                        id: product.id,
                        index: index,
                    },
                },
                onMouseDown: e => {
                    e.stopPropagation();
                },
            };
        },
        [ product, index ],
    );

    return (
        <Cmp
            {...attrs}
            className="look-card-product-actions"
            onClick={onEditClick}>
            <Dnd
                Component="a"
                href="/#"
                {...moveAttrs}
                className="look-card-product-drag">
                <IconDrag />
            </Dnd>
            <a
                href="/#"
                className="look-card-product-delete"
                onClick={onDeleteClick}>
                <IconDelete />
            </a>

            {(totalImages > 1 && !editMode)
                && (
                    <>
                        <a
                            href="/#"
                            className="look-card-product-lt"
                            onClick={onLtClick}>
                            <IconLt />
                        </a>
                        <a
                            href="/#"
                            className="look-card-product-gt"
                            onClick={onGtClick}>
                            <IconGt />
                        </a>
                    </>
                )}
        </Cmp>
    );
}

function CardBuilderProduct({ product, currentImage = 0, ...rest }) {
    const dispatch = useDispatch();
    const builder = useContext(LookBuilderContext);
    const look = useSelector(s => s.lookEditor.look);
    const syncImages = useSelector(s => s.lookEditor.ui.syncImages);
    const totalImages = useMemo(() => product.images?.length || 0, [ product ]);
    const [ currentImageIndex, setCurrentImageIndex ] = useState(currentImage);
    const [ layout, setLayout ] = useState(null);

    const activeSlot = useKey("activeSlot", builder);
    const activeSlotImageIndex = useKey("activeSlotImageIndex", builder);
    const editMode = activeSlot === rest.slotIndex;

    const ref = useDictRef({
        activeSlotImageIndex,
        activeSlot,
        editMode,
        syncImages,
        product,
        rest,
        layout,
        look,
    });

    const onLtClick = useSwallowEventCallback(
        () => {
            setCurrentImageIndex(prev => prev > 0 ? prev - 1 : totalImages - 1);
        },
        [ totalImages ],
    );

    const onGtClick = useSwallowEventCallback(
        () => {
            setCurrentImageIndex(prev =>
                prev >= totalImages - 1 ? 0 : prev + 1
            );
        },
        [ totalImages ],
    );

    const onMouseUp = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();

            removeListener(document.body, "mousemove", onMouseMove);
            removeListener(document.body, "mouseup", onMouseUp);

            if (ref.moving) {
                dispatch(lookData.layouts.update({
                    key: ref.product.id,
                    property: null,
                    value: { ...ref.layout },
                    image: 0, // ref.activeSlotImageIndex,
                    all: false, // ref.syncImages
                }));

                ref.moving = false;
                ref.startLayout = null;
                ref.layout = null;
                setLayout(null);
            }

            async(() => builder.set("slotImageMoving", false));
        },
        // eslint-disable-next-line
        [],
    );

    const onMouseMove = useCallback(
        (e) => {
            let currentImage = 0,
                start = ref.startLayout,
                layout = { ...ref.layout },
                // how far mouse moved
                xdiff = e.clientX - ref.startX,
                ydiff = e.clientY - ref.startY,
                div = ref.currDiv,
                // background position (hpos: left/right, vpos: top/bottom)
                // (vplus: xx%, hplus: xx%)
                hpos = start.h,
                hplus = start.hplus,
                vpos = start.v,
                vplus = start.vplus,
                // container/slot size
                w = div.offsetWidth,
                h = div.offsetHeight,
                tmpw,
                tmph,
                img = ref.product.images[currentImage],
                // full image size
                iw = img?.width,
                ih = img?.height,
                // image size ratio
                iratio = iw ? iw / ih : 0,
                // background-size property in percents
                // (with zoom applied)
                backSize = iratio
                    ? getBackgroundSize(
                        Object.assign(
                            {},
                            ref.look.layouts["template"][ref.activeSlot] || {},
                            layout,
                        ),
                        img,
                        ref.look.layouts["template"],
                        w,
                        h,
                    )
                    : 0;

            if (img?.horizontal) {
                tmph = (backSize * h) / 100;
                tmpw = tmph * iratio;
            }
            else {
                tmpw = (backSize / 100) * w; // / 100;
                tmph = tmpw / iratio;
            }

            const imgSize = { width: tmpw, height: tmph };
            const containerSize = { width: w, height: h };

            if (ydiff !== 0) {
                // if the image position is in Center
                if (vpos === "c") {
                    vpos = "t";
                    vplus = 50;
                }
                let { position, offset } = adjustPosition(
                    vpos,
                    vplus,
                    ydiff,
                    imgSize,
                    containerSize,
                );
                vpos = position;
                vplus = offset;
            }

            if (xdiff !== 0) {
                if (hpos === "c") {
                    hpos = "l";
                    hplus = 50;
                }

                let { position, offset } = adjustPosition(
                    hpos,
                    hplus,
                    xdiff,
                    imgSize,
                    containerSize,
                );
                hpos = position;
                hplus = offset;
            }

            layout.h = hpos;
            layout.v = vpos;
            layout.hplus = hplus;
            layout.vplus = vplus;

            ref.layout = layout;
            setLayout(layout);
        },
        // eslint-disable-next-line
        [],
    );

    const onMouseDown = useCallback(
        (e) => {
            if (!ref.editMode) {
                return;
            }

            const layout = getProductLayout(
                look.layouts[product.id],
                0,
            );

            builder.set("slotImageMoving", true);

            e.preventDefault();
            e.stopPropagation();
            addListener(document.body, "mousemove", onMouseMove);
            addListener(document.body, "mouseup", onMouseUp);
            ref.currDiv = e.target;
            ref.startX = e.clientX;
            ref.startY = e.clientY;
            ref.moving = true;
            ref.startLayout = JSON.parse(JSON.stringify(layout));
            ref.layout = JSON.parse(JSON.stringify(layout));
            setLayout(ref.layout);
        },
        // eslint-disable-next-line
        [ product, onMouseMove, onMouseUp, look, ref.editMode, builder ],
    );

    const attrs = useMemo(
        () => {
            if (ref.editMode) {
                return {
                    onMouseDown,
                };
            }
        },
        [ onMouseDown, ref.editMode ],
    );

    const overrides = useMemo(
        () => {
            if (layout) {
                return { layout };
            }
        },
        [ layout ],
    );

    useUpdateEffect(
        () => setCurrentImageIndex(currentImage),
        [ currentImage ],
    );

    useUpdateEffect(
        () => {
            if (activeSlot === rest.slotIndex) {
                builder.set(
                    "activeSlotCurrentImageIndex",
                    activeSlotImageIndex || currentImageIndex || 0,
                );
            }
        },
        [
            currentImage,
            currentImageIndex,
            activeSlotImageIndex,
            activeSlot,
            rest.slotIndex,
        ],
    );

    return (
        <ProductCard
            attrs={attrs}
            override={overrides}
            className={activeSlot === rest.slotIndex
                ? "active"
                : activeSlot !== null && activeSlot !== undefined
                ? "inactive"
                : ""}
            product={product}
            currentImage={!editMode
                ? currentImageIndex
                : activeSlotImageIndex || 0}
            {...rest}>
            <LookCardProductActions
                index={rest.slotIndex}
                product={product}
                onLtClick={onLtClick}
                onGtClick={onGtClick} />
        </ProductCard>
    );
}

export default CardBuilderProduct;
