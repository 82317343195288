import { useCallback, useContext, useState } from "react";
import { useOn } from "@kuindji/observable-react";

import ChatContext from "common/src/lib/chat/ChatContext";
import ProductCard from "common/src/refactor/components/catalogue/product/Card";
import Reaction from "common/src/refactor/components/catalogue/product/Reaction";

function CatalogueProductItem({ product }) {
    const chat = useContext(ChatContext);
    const onClick = useCallback(
        () => chat.trigger("messageAction", "review-catalogue", product),
        [product]
    );

    return (
        <ProductCard
            onClick={onClick}
            product={product}
            children={<Reaction reaction={product.reaction} />}
        />
    );
}

function MessageCatalogueProducts({ message }) {
    const chat = useContext(ChatContext);
    const [products, setProducts] = useState(() =>
        message.referencedProducts && message.referencedProducts.length > 0
            ? message.referencedProducts
            : chat.getReferencedProductCache(message.referencedProductIds)
    );

    const onCacheChange = useCallback(
        () =>
            setProducts(
                message.referencedProducts &&
                    message.referencedProducts.length > 0
                    ? message.referencedProducts
                    : chat.getReferencedProductCache(
                          message.referencedProductIds
                      )
            ),
        [message]
    );
    // const [ products, setProducts ] = useState(
    //     () => message.catalogueProducts && message.catalogueProducts.length > 0 ?
    //             message.catalogueProducts :
    //             chat.getCatalogueProductCache(message.catalogueProductIds)
    // );

    // const onCacheChange = useCallback(
    //     () => setProducts(
    //             message.catalogueProducts && message.catalogueProducts.length > 0 ?
    //                 message.catalogueProducts :
    //                 chat.getCatalogueProductCache(message.catalogueProductIds)),
    //     [ message ]
    // );

    // useOn(chat, "catalogueProducts", onCacheChange);
    useOn(chat, "referencedProducts", onCacheChange);

    return (
        <div className="chat-message-catalogue-products">
            {products.map((product) => (
                <CatalogueProductItem key={product.id} product={product} />
            ))}
        </div>
    );
}

export default MessageCatalogueProducts;
