import { preloadImage } from "common/src/lib/image/preload";
import { useEffect, useRef, useState } from "react";

const FALLBACK_SRC =
    "https://thefloorr-assets.s3.eu-west-2.amazonaws.com/images/broken-photo.png";

async function resolveSrc(srcs, resolveWithInfo = false) {
    for (const src of srcs) {
        if (typeof src !== "string") {
            if (src.src) {
                return src.src;
            }
            continue;
        }
        const info = await preloadImage(src, resolveWithInfo);
        if (info) {
            return resolveWithInfo ? info.src : src;
        }
    }
    return null;
}

export default function useImage(srcs, resolveWithInfo = false, hooks = {}) {
    const { setSource, setLoading } = hooks;
    const [ ldn, setLdn ] = useState(false);
    const [ src, setSrc ] = useState(null);
    const [ isFallback, setIsFallback ] = useState(false);
    const preloadedRef = useRef(false);

    useEffect(
        () => {
            if (!srcs || srcs.length === 0) {
                setSrc(FALLBACK_SRC);
                setTimeout(() => setSource?.(FALLBACK_SRC));
                setIsFallback(true);
                return;
            }

            resolveSrc(srcs, resolveWithInfo).then((src) => {
                preloadedRef.current = true;
                setSrc(src || FALLBACK_SRC);
                setLdn(false);
                setTimeout(() => setSource?.(src || FALLBACK_SRC));
                setTimeout(() => setLoading?.(false));
                setIsFallback(!src);
            });

            setTimeout(() => {
                if (!preloadedRef.current) {
                    setLdn(true);
                    setLoading?.(true);
                }
            }, 20);
        },
        [],
    );

    return { loading: ldn, src, isFallback };
}
