import prepareImgInfo from "./info";
import { storeImageSize } from "./sizeCache";

export function preloadImage(src, resolveWithInfo = true) {
    return new Promise((resolve, reject) => {
        if (!src) {
            resolve(null);
        }
        const img = new Image();
        img.onload = () => {
            const info = prepareImgInfo(img);
            storeImageSize(src, {
                width: info.width,
                height: info.height,
                ratio: info.ratio,
            });
            resolve(resolveWithInfo ? info : src);
        };
        img.onerror = () => resolve(null);
        img.src = src;
    });
}

export default function(srcs, resolveWithInfo = true) {
    const promises = srcs.map((src) => preloadImage(src, resolveWithInfo));

    return (
        Promise.allSettled(promises)
            // remove failed images
            .then((results) => results.map((r) => r.value))
            .then((imgs) => imgs.filter((img) => !!img))
    );
}
