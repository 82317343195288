import { useOn } from "@kuindji/observable-react";
import { Input } from "antd";
import Loader from "common/src/components/Loader";
import useDictRef from "common/src/hooks/useDictRef";
import Checkbox from "common/src/refactor/components/form/Checkbox";
import Radio from "common/src/refactor/components/form/Radio";
import Context from "common/src/refactor/lib/Context";
import { ReactComponent as Arrow } from "common/src/svg/angle-down.svg";
import { ReactComponent as Close } from "common/src/svg/close.svg";
import {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";

const groupObserver = new Context();

function FilterHead({
    expanded,
    onToggle,
    title,
    subtitle,
    hasSelection,
    onClear,
    showClear,
}) {
    const cls = useMemo(
        () => {
            return [
                "expandable-filter-head",
                expanded ? "expandable-filter-head-expanded" : "",
            ].join(" ");
        },
        [ expanded ],
    );

    const onToggleClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            onToggle(!expanded);
        },
        [ onToggle, expanded ],
    );

    const onClearClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            onClear?.();
        },
        [ onClear ],
    );

    return (
        <div className={cls} onClick={onToggleClick}>
            <div className="expandable-filter-head-main">
                <p className="expandable-filter-head-title">{title}</p>
                {subtitle && (
                    <p className="expandable-filter-head-subtitle">
                        {subtitle}
                    </p>
                )}
            </div>
            {(showClear && hasSelection) && (
                <a
                    href="/#"
                    onClick={onClearClick}
                    className="expandable-filter-head-clear">
                    <Close />
                </a>
            )}
            <span href="/#" className="expandable-filter-head-toggle">
                <Arrow />
            </span>
        </div>
    );
}

function FilterSearch({ query, onQueryChange, placeholder }) {
    return (
        <div className="expandable-filter-search">
            <Input
                allowClear
                value={query}
                placeholder={placeholder}
                onChange={e => onQueryChange(e.target.value)}
                onClear={() => onQueryChange("")} />
        </div>
    );
}

function FilterItem(
    { value, name, item, selection, onChange, multiple, renderOption },
) {
    const hasSelection = Object.keys(selection).length > 0;
    const selected = (value === null && !hasSelection) || !!selection[value];
    const disabled = (!hasSelection && value === null) || item?.disabled;

    const onItemClick = useCallback(
        (checked) => {
            onChange?.(value, checked);
        },
        [ onChange ],
    );

    const label = useMemo(
        () => {
            if (!renderOption) {
                return name;
            }
            const content = renderOption(item);
            return content || name;
        },
        [ renderOption, item, name ],
    );

    return (
        <div className="expandable-filter-item">
            {multiple
                ? (
                    <Checkbox
                        label={label}
                        disabled={disabled}
                        checked={selected}
                        onChange={onItemClick} />
                )
                : (
                    <Radio
                        size="small"
                        label={label}
                        disabled={disabled}
                        checked={selected}
                        onChange={onItemClick} />
                )}
        </div>
    );
}

function FilterBody({
    searchable,
    searchPlaceholder,
    multiple,
    loading,
    showAll,
    query,
    onQueryChange,
    items,
    selection,
    onItemChange,
    onEndReached,
    renderOption,
    allValue,
}) {
    const onScroll = useCallback(
        (e) => {
            const el = e.target;
            if (el.scrollTop / (el.scrollHeight - el.offsetHeight) > 0.75) {
                onEndReached?.();
            }
        },
        [ onEndReached ],
    );

    return (
        <div className="expandable-filter-body" onScroll={onScroll}>
            {searchable && (
                <FilterSearch
                    query={query}
                    onQueryChange={onQueryChange}
                    placeholder={searchPlaceholder} />
            )}
            <div className="expandable-filter-items">
                {showAll && (
                    <FilterItem
                        key="__all"
                        value={allValue}
                        name="All"
                        item={null}
                        selection={selection}
                        onChange={onItemChange}
                        multiple={multiple}
                        renderOption={renderOption} />
                )}
                {items.map(item => (
                    <FilterItem
                        key={item.value}
                        value={item.value}
                        name={item.label}
                        item={item}
                        selection={selection}
                        onChange={onItemChange}
                        multiple={multiple}
                        renderOption={renderOption} />
                ))}
                {loading && (
                    <div className="expandable-filter-loading">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
}

// item type: { label, key, value }

function ExpandableFilter({
    id,
    name,
    searchable = false,
    searchPlaceholder = "Search",
    multiple = false,
    group = null,
    onSearch,
    onChange,
    onLoadMore,
    onToggle,
    loading = false,
    showAll = false,
    showClear = true,
    plainValue = false,
    renderOption,
    allValue = null,
    items = [],
    value = [],
}, apiRef) {
    const [ expanded, setExpanded ] = useState(false);
    const [ query, setQuery ] = useState("");
    const inGroupName = id || name;
    const ref = useDictRef({ expanded, group, name, inGroupName });

    const selectionMap = useMemo(
        () => {
            if (!Array.isArray(value)) {
                if (value) {
                    return { [value]: true };
                }
                return {};
            }
            return value.reduce((acc, item) => {
                acc[item] = true;
                return acc;
            }, {});
        },
        [ value ],
    );

    const hasSelection = useMemo(
        () => {
            if (Array.isArray(value)) {
                if (value.length === 0) {
                    return false;
                }
                else if (value.length === 1) {
                    return value[0] !== allValue;
                }
                return value.length > 0;
            }
            return value !== allValue && !!value;
        },
        [ value, allValue ],
    );

    const selectedNames = useMemo(
        () => {
            const values = Array.isArray(value)
                ? value
                : value
                ? [ value ]
                : [];

            if (!hasSelection) {
                return showAll ? "All" : null;
            }
            return values.map(v => items.find(item => item.value === v)?.label)
                .filter(v => !!v)
                .join(", ");
        },
        [ value, items, showAll, hasSelection ],
    );

    const onQueryChange = useCallback(
        (query) => {
            setQuery(query);
            onSearch?.(query);
        },
        [ onSearch ],
    );

    const onItemChange = useCallback(
        (itemValue, checked) => {
            if (itemValue === allValue) {
                onChange?.(
                    plainValue
                        ? allValue
                        : allValue
                        ? [ allValue ]
                        : [],
                );
                return;
            }
            const item = items.find(item => item.value === itemValue);
            const sel = Array.isArray(value)
                ? value.find(v => v === itemValue)
                : value === itemValue;
            if (multiple) {
                if (checked && !sel) {
                    onChange?.([ ...(value || []), item.value ]);
                }
                else if (!checked && sel) {
                    onChange?.((value || []).filter(v => v !== itemValue));
                }
            }
            else {
                if (checked && !sel) {
                    onChange?.(plainValue ? item.value : [ item.value ]);
                }
                else if (!checked && sel) {
                    onChange?.(plainValue ? undefined : []);
                }
            }
        },
        [ items, value, multiple, onChange, plainValue, allValue ],
    );

    const onClear = useCallback(
        () => {
            onChange?.(plainValue ? undefined : []);
        },
        [ onChange, plainValue ],
    );

    const onEndReached = useCallback(
        () => {
            onLoadMore?.();
        },
        [ onLoadMore ],
    );

    const onHeadToggle = useCallback(
        (expanded) => {
            setExpanded(expanded);
            onToggle?.(expanded);
            if (expanded) {
                groupObserver.set(ref.group, ref.inGroupName);
            }
        },
        [ onToggle ],
    );

    const onGroupChange = useCallback(
        (currentOpenFilter) => {
            if (ref.group && ref.inGroupName) {
                if (currentOpenFilter !== ref.inGroupName) {
                    setExpanded(false);
                }
            }
        },
        [ setExpanded ],
    );

    useOn(groupObserver, group || "default", onGroupChange);

    useImperativeHandle(
        apiRef,
        () => ({
            toggle() {
                setExpanded(!ref.expanded);
                if (!ref.expanded && ref.group && ref.inGroupName) {
                    groupObserver.set(ref.group, ref.inGroupName);
                }
            },
        }),
    );

    return (
        <div className="expandable-filter">
            <FilterHead
                expanded={expanded}
                onToggle={onHeadToggle}
                title={name}
                subtitle={selectedNames}
                hasSelection={hasSelection}
                onClear={onClear}
                showClear={showClear} />
            {expanded && (
                <FilterBody
                    loading={loading}
                    searchable={searchable}
                    searchPlaceholder={searchPlaceholder}
                    showAll={showAll && items && items.length > 0}
                    items={items}
                    hasSelection={hasSelection}
                    allValue={allValue}
                    selection={selectionMap}
                    multiple={multiple}
                    query={query}
                    onQueryChange={onQueryChange}
                    onItemChange={onItemChange}
                    onEndReached={onEndReached}
                    renderOption={renderOption} />
            )}
        </div>
    );
}

export default forwardRef(ExpandableFilter);
