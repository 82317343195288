import React, { useState, useCallback, useMemo, useContext } from "react";
import { ReactComponent as IconClose } from "common/src/svg/close.svg";

//import LookProductCard from "common/src/components/look/ProductCard"
import CatalogueProductCard from "common/src/refactor/components/catalogue/product/Card";
import MoodboardCard from "common/src/refactor/components/moodboard/Card";

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";
import ChatContext from "common/src/lib/chat/ChatContext";
import { useOn } from "@kuindji/observable-react";

import ConsultationCard from "common/src/refactor/components/consultation/Card";
import LookCard from "common/src/refactor/components/look/Card";

const atchTypes = [
    "look",
    "product",
    "image",
    "catalogue",
    "moodboard",
    "consultation",
];

function AttachmentLook({ look }) {
    return (
        <div className="chat-form-attachment-look">
            <LookCard look={look} showTags={false} />
            {look.title && <h4>{look.title}</h4>}
        </div>
    );
}

function AttachmentLookProduct({ product }) {
    const image = useMemo(() => {
        const i = product.images[0];
        return typeof i === "string" ? i : i.src;
    }, [product]);

    return (
        <div
            className="chat-form-attachment-product"
            style={{ backgroundImage: `url(${image})` }}
        />
    );
}

function Attachment({ attachment }) {
    const chat = useContext(ChatContext);
    const onRemove = useSwallowEventCallback(
        () =>
            chat.removeAttachment(
                attachment.type,
                attachment.value.id || attachment.value.localId
            ),
        [attachment]
    );

    const content = useMemo(() => {
        if (attachment.type === "look") {
            return <AttachmentLook look={attachment.value} />;
        } else if (attachment.type === "product") {
            return <AttachmentLookProduct product={attachment.value} />;
        } else if (attachment.type === "catalogue") {
            return (
                <CatalogueProductCard
                    footer={false}
                    retailer={false}
                    product={attachment.value}
                    showFooter={false}
                />
            );
        } else if (attachment.type === "moodboard") {
            return <MoodboardCard moodboard={attachment.value} />;
        } else if (attachment.type === "image") {
            return (
                <div
                    className="chat-form-attachment-image"
                    style={{ backgroundImage: `url(${attachment.value.src})` }}
                />
            );
        } else if (attachment.type === "consultation") {
            return (
                <ConsultationCard
                    cons={attachment.value}
                    showCounts={false}
                    showDescription={false}
                    showStatus={false}
                    showDate={false}
                />
            );
        }
    }, [attachment]);

    return (
        <div className="chat-form-attachment">
            <a
                href="/#"
                className="chat-form-attachment-remove"
                onClick={onRemove}>
                <IconClose />
            </a>
            {content}
        </div>
    );
}

function Attachments() {
    const chat = useContext(ChatContext);
    const [attachments, setAttachments] = useState(
        chat.getAttachments(atchTypes)
    );

    const onAttachmentsChange = useCallback(
        () => setAttachments(chat.getAttachments(atchTypes)),
        []
    );

    useOn(chat, "attachments", onAttachmentsChange);

    if (attachments.length === 0) {
        return null;
    }

    return (
        <div className="chat-form-attachments">
            <div className="chat-form-attachments-list">
                {attachments.map((a) => (
                    <Attachment key={a.id} attachment={a} />
                ))}
            </div>
        </div>
    );
}

export default Attachments;
