import { batch } from "react-redux";
import { lookLoader } from "./looks";
import api from "app/api";
import hub from "common/src/hub";
import user from "common/src/user";
import prepareWhere from "common/src/lib/prepareWhere";
import {
    restoreContact,
    restoreSize /*, loadPreviousSizes*/,
} from "common/src/actions/consultation";
import entities from "common/src/api/hasura/entities";

import store from "app/store";
import { data, ui } from "common/src/store/consultations";
import {
    getConsultationProductReferences,
    referencedProductsLoader,
} from "./productReference";

let subscription;
let lastLooksCacheSet;

const statusOrder = {
    new: 0,
    inprogress: 1,
    completed: 2,
    cancelled: 3,
};

export const consultationLoader = (id, options = {}) => {
    options.where = { id };
    return consultationsLoader(options).then((items) => items[0]);
};

export const consultationsLoader = async (options) => {
    const {
        order,
        limit = 15,
        offset = 0,
        consultations = null, // skip consultations themselves, fetch looks and stuff
        looksCacheSet = null,
        withCount = false,
        withLooks = false,
        withSizes = false,
        withContacts = false,
        withSavedCons = false,
        withReactions = false,
        withCustomerReactions = false,
        withInvitations = false,
        withPublishedLooks = true,
        withMyLastMessage = false,
    } = options;

    const where = prepareWhere(options.where || {});

    let items, count;
    let graph = entities.Consultation.list;
    if (consultations) {
        items = consultations;
        count = consultations.length;
    } else {
        if (withInvitations) {
            graph += " invitation { id } ";
        }

        graph += " hidden ";
        const opt = limit
            ? { where, order, offset, limit }
            : { where, order, offset };
        const response = await api.consultation.list(opt, graph, withCount);

        items = withCount ? response.items : response;
        count = withCount ? response.count : null;
    }
    const ids = items.map((c) => c.id);
    const consMap = {};

    items.forEach((c) => {
        if (!c.looks || ((withLooks || withPublishedLooks) && ids.length)) {
            c.looks = [];
        }
        !c.contacts && (c.contacts = []);
        !c.sizes && (c.sizes = []);
        consMap[c.id] = c;
    });

    if ((withLooks || withPublishedLooks) && ids.length) {
        const lookWhere = {
            consultationId: { _in: ids },
            deleted: { _eq: false },
        };

        if (withPublishedLooks && withLooks === false) {
            lookWhere.published = { _eq: true };
        }

        const looksPayload = {
            limit: 1000,
            where: lookWhere,
            order: {
                createdAt: "desc",
            },
            lookFields: "consultationId",
            withReactions,
            reactionsUserId: false,
            withMyLastMessage,
            withProductStock: true,
            cacheSet: looksCacheSet,
        };

        if (withCustomerReactions) {
            if (items.length === 1) {
                looksPayload.withReactions = true;
                looksPayload.reactionsUserId = items[0].customerId;
            } else {
                looksPayload.withReactions = false;
            }
        }

        const looks = await lookLoader(looksPayload);

        looks.forEach((l) => {
            const cid = l.consultationId;
            consMap[cid].looks.push(l);
        });
    }

    if (withContacts && ids.length) {
        const contacts = await api.consultationContact.list({
            where: { consultationId: { _in: ids } },
        });
        contacts.forEach((c) => {
            consMap[c.consultationId].contacts.push(c.contact);
        });
    }

    if (withSavedCons && ids.length) {
        const savedCons = await api.userSavedConsultation.list({
            where: { consultationId: { _in: ids } },
        });
        items.forEach(
            (c) => (c.saved = savedCons.some((s) => s.consultationId === c.id))
        );
    }

    if (withSizes && ids.length) {
        const userIds = items.map((c) => c.customerId).filter((id) => !!id);
        if (userIds.length > 0) {
            const where = { userId: { _in: userIds } };
            const sizes = await api.userSize.list({ where });
            sizes.forEach((s) => {
                const cons = items.find((c) => c.customerId === s.userId);
                if (cons) {
                    cons.sizes.push(s);
                }
            });
        }
    }

    items.forEach((c) => {
        c.contacts = c.contacts.map((c) => restoreContact(c));
        c.sizes = c.sizes.map((s) => restoreSize(s));
    });

    return withCount ? { items, count } : items;
};

export let updatePaymentDetails = async function (cons, details) {
    if (!cons.paymentDetailsId) {
        const pd = await api.userPaymentDetails.create({
            userId: user.id(),
            ...details,
        });
        await api.consultation.update(cons.id, { paymentDetailsId: pd.id });
        hub.dispatch("consultation", "payment-details-created", cons.id);
    } else {
        await api.userPaymentDetails.update(cons.paymentDetailsId, details);
        hub.dispatch("consultation", "payment-details-updated", cons.id);
    }
};

export const reloadConsultations = async () => {
    //console.log("reloading consultations")
    await receiveConsultations(null, lastLooksCacheSet, true /* remove old */);
};

function setSubscriptionWhere(where, options = {}) {
    if (user.isOnly("User")) {
        where.customerId = { _eq: user.id() };
        where.friId = { _is_null: false };
        where.fri = {
            userConnections: {
                connection: {
                    users: {
                        userId: {
                            _eq: user.id(),
                        },
                    },
                },
            },
        };
        where.hidden = { _eq: false };
    } else {
        where.friId = { _eq: user.id() };
        where._or = [
            {
                customerId: { _is_null: true },
            },
            {
                customer: {
                    userConnections: {
                        connection: {
                            users: {
                                userId: {
                                    _eq: user.id(),
                                },
                            },
                        },
                    },
                },
            },
        ];

        if (options.activeOnly) {
            where.status = {
                _nin: ["cancelled", "cancelRequest"],
            };
        }
    }
}

async function receiveConsultations(
    cs,
    looksCacheSet = lastLooksCacheSet,
    removeOld = false
) {
    //console.log("receive", cs?.length, removeOld);
    const payload = {
        withLooks: !user.isOnly("User"), // for shopper withLooks=true returns all looks,
        // not only published
        withReactions: true,
        withSavedCons: user.isOnly("User"),
        withMyLastMessage: true,
        reactionUserId: false,
        looksCacheSet,
        limit: false,
    };
    if (cs) {
        payload.consultations = cs;
    } else {
        payload.where = {};
        setSubscriptionWhere(payload.where);
    }
    const newConsultations = await consultationsLoader(payload);
    let consultations = [...store.getState().consultations.data.consultations];

    newConsultations.forEach((c) => {
        const inx = consultations.findIndex((exc) => exc.id === c.id);
        if (inx !== -1) {
            consultations[inx] = c;
        } else {
            consultations.push(c);
        }
    });

    if (removeOld && consultations.length > 0) {
        consultations = consultations.filter((c) => {
            return newConsultations.findIndex((nc) => nc.id === c.id) !== -1;
        });
    }

    consultations = consultations.sort((a, b) => {
        const s1 = statusOrder[a.status];
        const s2 = statusOrder[b.status];

        if (s1 !== s2) {
            return s1 < s2 ? -1 : 1;
        }

        const d1 = "" + (a.lastUpdateAt || a.createdAt);
        const d2 = "" + (b.lastUpdateAt || b.createdAt);
        return d1 === d2 ? 0 : d1 > d2 ? -1 : 1;
    });

    batch(() => {
        store.dispatch(ui.consultations.loading(false));
        store.dispatch(ui.consultations.loaded(true));
        store.dispatch(data.consultations.set(consultations));
    });
}

export const subscribeToUserConsultations = async ({
    looksCacheSet = null,
    options = {},
}) => {
    if (subscription) {
        return;
    }

    lastLooksCacheSet = looksCacheSet;

    store.dispatch(ui.consultations.loading(true));

    const graph = entities.Consultation.list;
    const limit = 100;
    const where = {
        // status: { _in: [ "new", "inprogress", "request" ] },
    };
    const order = { lastUpdateAt: "desc", createdAt: "desc" };

    setSubscriptionWhere(where, options);

    subscription = await api.consultation.subscribeList(
        { where, order, limit },
        graph,
        async (cs) => {
            return await receiveConsultations(cs, looksCacheSet, true);
        }
    );
};

export const unsubscribeFromUserConsultations = () => {
    if (subscription) {
        if (subscription.subscription) {
            subscription.subscription.unsubscribe();
        }
        subscription = null;
    }
};

export const clearConsultations = () => {
    batch(() => {
        store.dispatch(ui.consultations.loading(false));
        store.dispatch(ui.consultations.loaded(false));
        store.dispatch(data.consultations.set([]));
    });
};

export async function searchWithinConsultations({ consultations, query }) {
    const cids = consultations.map((c) => c.id);
    if (!query || cids.length === 0) {
        return cids;
    }
    const { productId, productToConsultationMap } =
        await getConsultationProductReferences({
            consultationId: cids,
        });

    if (productId.length > 0) {
        const { data: product } = await referencedProductsLoader({
            productIds: productId,
            returnIds: true,
            query,
        });

        const foundConsultations = new Set();
        product.forEach((id) =>
            foundConsultations.add(productToConsultationMap[id])
        );

        return Array.from(foundConsultations);
    }

    return [];
}
