import { Input, Spin } from "antd";
import { useCallback, useContext, useState } from "react";

import { ReactComponent as IconBack } from "common/src/svg/back.svg";
import { ReactComponent as IconSearch } from "common/src/svg/search.svg";

import Loading from "app/components/builder/search/Loading";
import NullForm from "common/src/components/NullForm";
import Button from "common/src/refactor/components/button/Button";
import ProductCard from "common/src/refactor/components/catalogue/product/Card";

import * as productEditor from "common/src/actions/look/product";
// import * as actions from "common/src/actions/moodboards";
import { referencedProductsLoader } from "common/src/actions/productReference";
import useDictRef from "common/src/hooks/useDictRef";
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";
import Spacer from "common/src/refactor/components/Spacer";
import useKey from "common/src/refactor/hooks/useKey";
import useQuery from "common/src/refactor/hooks/useQuery";
import useInputHander from "common/src/refactor/hooks/userInputHandler";
import LookBuilderContext from "../LookBuilderContext";

const PER_PAGE = 48;

function LookBuilderSearchMoodboard({ open = false }) {
    // const { message } = AntApp.useApp();
    const builder = useContext(LookBuilderContext);
    const moodboard = useKey("searchMoodboard", builder);
    const [ selecting, setSelecting ] = useState(false);
    const [ page, setPage ] = useState(0);
    const [ query, setQuery ] = useState("");
    const [ appliedQuery, setAppliedQuery ] = useState("");
    const loadMore = useCallback(() => setPage((page) => page + 1), []);

    const {
        data,
        setData: setProducts,
        isLoading,
        extraData: { hasMore },
    } = useQuery(
        referencedProductsLoader,
        [ moodboard?.id, page, appliedQuery ],
        {
            enabled: !!moodboard,
            rowKey: "id",
            append: page > 0,
            params: {
                moodboardId: moodboard?.id,
                limit: PER_PAGE,
                offset: page * PER_PAGE,
                query: appliedQuery,
            },
            processResponse: (response) => {
                return {
                    data: response,
                    count: response.count,
                    hasMore: response.length >= PER_PAGE,
                };
            },
        },
    );
    // console.log(data);
    const products = data?.data || [];

    const ref = useDictRef({ loadMore, hasMore, isLoading });

    const handleQueryChange = useInputHander(setQuery);
    const onSearch = useCallback(
        (value, e, { source }) => {
            if (source === "clear") {
                setPage(0);
                setQuery("");
                setAppliedQuery("");
            }
            else {
                setPage(0);
                setAppliedQuery(value);
                setProducts([]);
            }
        },
        [ setQuery, setProducts ],
    );

    const onProductClick = useCallback(
        async (mp) => {
            // console.log(mp);
            const product = { ...mp };
            product.referencedProductId = product.id;
            product.referencedProduct = mp;
            product.id = "tmp-" + product.id;
            // product = productEditor.normalizeCatalogueProduct(product);
            if (product.images) {
                setSelecting(true);
                product.images = await productEditor.preloadProductImages(
                    product.images,
                );
                setSelecting(false);
            }
            // let product = null;

            // setSelecting(true);

            // try {
            //     // product = await productEditor.getCatalogueProduct(
            //     //     mp.retailer,
            //     //     mp.productWebId
            //     // );
            //     // if (!product || !product.images || !product.images.length) {
            //     //     product = await productEditor.scrapeUrl(mp.url);
            //     // }
            //     if (product.images) {
            //         product.images = await productEditor.preloadProductImages(
            //             product.images,
            //         );
            //     }
            // }
            // catch (err) {
            //     console.log(err);
            // }

            // setSelecting(false);

            // if (!product) {
            //     message.error({
            //         content:
            //             "We can no longer find this product in the catalogue or on retailer website",
            //     });
            //     await actions.removeProduct(moodboard.id, mp.id);
            //     return;
            // }

            builder.trigger("search-select-product", product);
        },
        [ builder ],
    );

    const onBackClick = useSwallowEventCallback(
        () => {
            builder.goBack();
        },
        [ builder ],
    );

    const onScroll = useCallback(
        (e) => {
            const el = e.target;
            if (ref.hasMore && !ref.isLoading) {
                if (el.scrollTop / (el.scrollHeight - el.offsetHeight) > 0.75) {
                    ref.loadMore();
                }
            }
        },
        // eslint-disable-next-line
        [],
    );

    if (!open) {
        return null;
    }

    return (
        <div className="look-builder-search-moodboard">
            <Spacer size="0rem" />
            {selecting && <Loading />}
            <NullForm className="toolbar">
                <Input.Search
                    placeholder="Search moodboards"
                    size="large"
                    enterButton={
                        <Button
                            Component="a"
                            onClick={(e) => e.preventDefault()}
                            Icon={IconSearch}
                            text="Search"
                            type="primary" />
                    }
                    allowClear
                    onChange={handleQueryChange}
                    value={query}
                    onSearch={onSearch} />
            </NullForm>
            <div className="look-builder-search-moodboard-header">
                <a href="/#" onClick={onBackClick}>
                    <IconBack />
                    Back
                </a>
                <h4>{moodboard.name}</h4>
            </div>
            <div
                className="look-builder-search-moodboard-products"
                onScroll={onScroll}>
                {products
                    ? products.map((p) => (
                        <ProductCard
                            key={p.id}
                            product={p}
                            onClick={onProductClick} />
                    ))
                    : null}
                {(hasMore || (isLoading && products.length === 0)) && (
                    <Spin spinning className="infinite-scroll-spinner" />
                )}
            </div>
        </div>
    );
}

export default LookBuilderSearchMoodboard;
