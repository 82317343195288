import { useContext, useState, useCallback } from "react";
import { Input } from "antd";

import Button from "common/src/refactor/components/button/Button";
import ProductCard from "common/src/refactor/components/catalogue/product/Card";
import MoodboardCard from "common/src/refactor/components/moodboard/Card";
import NullForm from "common/src/components/NullForm";
import MoodboardDrawer from "common/src/refactor/components/moodboard/Drawer";
import Radio from "common/src/refactor/components/form/Radio";

import { ReactComponent as IconBack } from "common/src/svg/back.svg";
import { ReactComponent as IconSearch } from "common/src/svg/search.svg";
import { ReactComponent as IconAdd } from "common/src/svg/plus.svg";

import * as actions from "common/src/actions/moodboards";
import * as moodboardActions from "common/src/actions/moodboard";
import LookBuilderContext from "../LookBuilderContext";
import useKey from "common/src/refactor/hooks/useKey";
import useQuery from "common/src/refactor/hooks/useQuery";
import useInputHander from "common/src/refactor/hooks/userInputHandler";
import useDictRef from "common/src/hooks/useDictRef";
import useSelection from "common/src/refactor/hooks/useSelection";
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";
import { App as AntApp } from "antd";

const PER_PAGE = 48;

function LookBuilderSearchAddToMoodboard({ open = false }) {
    const builder = useContext(LookBuilderContext);
    const products = useKey("addToMoodboardProducts", builder);
    const [adding, setAdding] = useState(false);
    const {
        selection,
        toggle: toggleSelection,
        isSelected,
        setSelection,
    } = useSelection({});
    const [page, setPage] = useState(0);
    const [query, setQuery] = useState("");
    const [appliedQuery, setAppliedQuery] = useState("");
    const loadMore = useCallback(() => setPage((page) => page + 1), []);
    const { message } = AntApp.useApp();

    const {
        data: moodboards,
        isLoading,
        refetch,
        extraData: { hasMore },
    } = useQuery(actions.loadMoodboards, [page, appliedQuery], {
        params: {
            // we can add only to our own moodboards
            mine: true,
            page,
            query: appliedQuery,
            perPage: PER_PAGE,
        },
        rowKey: "id",
        append: page > 0,
        processResponse: (response) => {
            return {
                data: response.items,
                count: response.count,
                hasMore: response.items.length >= PER_PAGE,
            };
        },
        initialData: [],
    });

    const ref = useDictRef({ loadMore, hasMore, isLoading });

    const handleQueryChange = useInputHander(setQuery);
    const onSearch = useCallback(
        (value, e, { source }) => {
            if (source === "clear") {
                setQuery("");
            }
            setPage(0);
            setAppliedQuery(value);
        },
        [setQuery]
    );

    const onCreateMoodboardClick = useCallback(() => {
        MoodboardDrawer.show().then((m) => {
            if (m) {
                refetch();
            }
        });
    }, [refetch]);

    const onScroll = useCallback(
        (e) => {
            const el = e.target;
            if (ref.hasMore && !ref.isLoading) {
                if (el.scrollTop / (el.scrollHeight - el.offsetHeight) > 0.75) {
                    ref.loadMore();
                }
            }
        },
        // eslint-disable-next-line
        []
    );

    const onBackClick = useSwallowEventCallback(() => {
        builder.goBack();
    }, [builder]);

    const onAddToMoodboardClick = useCallback(async () => {
        setAdding(true);

        for (const moodboard of selection) {
            await moodboardActions.addProducts(moodboard.id, products);
        }

        message.success({
            content: "Product added to Moodboard",
            icon: <></>,
        });
        setAdding(false);
        setSelection([]);
        builder.set("addToMoodboardProducts", []);
        builder.goBack();
    }, [products, selection, builder, setSelection, message]);

    if (!open) {
        return null;
    }

    return (
        <div className="look-builder-search-add-to-moodboard">
            <div className="toolbar">
                <a href="/#" onClick={onBackClick}>
                    <IconBack />
                    Cancel
                </a>
                <Button
                    loading={adding}
                    onClick={onAddToMoodboardClick}
                    disabled={selection.length === 0 || adding}
                    type="primary"
                    text="Add to moodboard"
                />
            </div>
            <div className="look-builder-search-add-to-moodboard-layout">
                <div className="look-builder-search-add-to-moodboard-products">
                    {products.map((p) => (
                        <ProductCard key={p.id} product={p} />
                    ))}
                </div>
                <div className="look-builder-search-add-to-moodboard-moodboards">
                    <NullForm className="look-builder-search-add-to-moodboard-search">
                        <Input.Search
                            placeholder="Search moodboards"
                            size="large"
                            enterButton={
                                <Button
                                    Component="a"
                                    onClick={(e) => e.preventDefault()}
                                    Icon={IconSearch}
                                    text="Search"
                                    type="primary"
                                />
                            }
                            allowClear
                            onChange={handleQueryChange}
                            value={query}
                            onSearch={onSearch}
                        />
                    </NullForm>
                    <div className="toolbar">
                        <h4>Choose moodboards</h4>
                        <Button
                            type="text"
                            onClick={onCreateMoodboardClick}
                            Icon={IconAdd}
                            text="Create moodboard"
                        />
                    </div>
                    <div
                        className="look-builder-search-add-to-moodboard-cards"
                        onScroll={onScroll}>
                        {moodboards.map((m) => (
                            <MoodboardCard
                                onClick={toggleSelection}
                                key={m.id}
                                moodboard={m}>
                                <Radio size="medium" checked={isSelected(m)} />
                            </MoodboardCard>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LookBuilderSearchAddToMoodboard;
