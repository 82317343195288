const cache = new Map();

export function storeImageSize(src, sizeInfo) {
    cache.set(src, {
        sizeInfo,
        timestamp: Date.now(),
    });
}

export function getImageSize(src) {
    return cache.get(src)?.sizeInfo || null;
}

function cleanup() {
    const now = Date.now();
    for (const [ src, item ] of cache.entries()) {
        if (now - item.timestamp > 1000 * 60 * 60 * 12) {
            cache.delete(src);
        }
    }
}

setInterval(cleanup, 1000 * 60 * 5);
