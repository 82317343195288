export const grossEarningsInfoText =
    "Gross earnings are the total amount earned "
    + "before returns and refunds are deducted. \n"
    + "Net earnings are the total amount after these deductions "
    + "have been made and reflects the more up-to-date pending Earnings figure. \n"
    + "Totals may vary due to live currency rate calculations.";

export const grossSalesInfoText = "Gross sales are the total amount sold "
    + "before returns and refunds are deducted. \n"
    + "Net sales are the total amount after these deductions "
    + "have been made and reflects the more up-to-date pending Sales figure. \n"
    + "Totals may vary due to live currency rate calculations.";

export const approvedInfoText =
    "'APPROVED' reflects the amount currently confirmed by the affiliate. \n"
    + "This figure is subject to change once late returns have been accounted for. \n"
    + "'PENDING' shows any new sales awaiting approval by the affiliate, and \n"
    + "'RETURNS' represents the amount that is deducted due to returns and refunds. \n"
    + "Totals may vary due to live currency rate calculations.";

export const approvedSalesInfoText =
    "'APPROVED' reflects the amount currently confirmed by the affiliate. \n"
    + "This figure is subject to change once late returns have been accounted for. \n"
    + "'PENDING' shows any new sales awaiting approval by the affiliate, \n"
    + "'RETURNS' represents the amount that is deducted due to returns and refunds and \n"
    + "'LOCKED' means the sale has been fully approved, and we are awaiting payout from the retailer. \n"
    + "Totals may vary due to live currency rate calculations.";

export const comissionInfoText =
    "This amount represents total commission payments to you since joining THE FLOORR. \n"
    + "Totals may vary due to live currency rate calculations.";
