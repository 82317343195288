import { loadDesigners } from "common/src/actions/catalogue/designers";
import useDictRef from "common/src/hooks/useDictRef";
import useUpdateEffect from "common/src/hooks/useUpdateEffect";
import useQuery from "common/src/refactor/hooks/useQuery";
import { useCallback, useMemo, useState } from "react";

const PER_PAGE = 20;

function designerToOption(d) {
    return { ...d, label: d.name, key: d.id, value: d.id };
}

export default function useDesignerSelector({
    value,
    onItemsChange,
    itemToOption,
    perPage = PER_PAGE,
} = {}) {
    const [ start, setStart ] = useState(0);
    const [ query, setQuery ] = useState("");

    const { data: designers, isLoading, isLoaded, extraData: { hasMore } } =
        useQuery(
            loadDesigners,
            [ start, query, perPage ],
            {
                append: start > 0,
                params: {
                    offset: start,
                    limit: perPage,
                    query,
                },
            },
            [ query, perPage ],
        );

    const initialIds = useMemo(
        () => {
            if (!value) {
                return [];
            }
            return value.map(v => typeof v === "string" ? v : v.id).filter(v =>
                !!v
            );
        },
        [ value ],
    );

    const additionalSearchRequired = useMemo(
        () => {
            if (!initialIds || initialIds.length === 0) {
                return false;
            }
            if (!designers || designers.length === 0) {
                return true;
            }
            return initialIds.map(id => !designers.find(d => d.id === id))
                .length > 0;
        },
        [ initialIds, designers ],
    );

    const { data: initialDesigners, isLoading: isInitialLoading } = useQuery(
        loadDesigners,
        [ initialIds ],
        {
            enabled: additionalSearchRequired,
            params: {
                id: initialIds,
            },
        },
        [],
    );

    const ref = useDictRef({ isLoading, hasMore });

    const search = useCallback(
        (query) => {
            setQuery(query);
            setStart(0);
        },
        [],
    );

    const loadMore = useCallback(
        () => {
            if (!ref.isLoading && ref.hasMore) {
                setStart(prev => prev + perPage);
            }
        },
        [ perPage ],
    );

    const allDesigners = useMemo(
        () => {
            const all = [];
            const ids = {};
            designers && designers.forEach(d => {
                ids[d.id] = true;
                all.push(d);
            });
            initialDesigners && initialDesigners.forEach(d => {
                if (!ids[d.id]) {
                    all.push(d);
                }
            });
            return all;
        },
        [ designers, initialDesigners ],
    );

    useUpdateEffect(
        () => {
            if (onItemsChange) {
                onItemsChange(allDesigners);
            }
        },
        [ allDesigners, onItemsChange ],
    );

    const options = useMemo(
        () => allDesigners.map(itemToOption || designerToOption),
        [ allDesigners, itemToOption ],
    );

    return {
        allDesigners,
        options,
        search,
        loadMore,
        hasMore,
        isLoading: isLoading || isInitialLoading,
        isLoaded,
        start,
        setStart,
        query,
        setQuery,
    };
}
