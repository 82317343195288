import api from "common/src/api"

export async function loadRetailers({ offset = 0, limit, query, id, region, withCommission }) {
    let retailers;
    const body = { offset }
    limit && (body.limit = limit);
    query && (body.query = query);
    id && (body.id = id);
    region && (body.region = region);
    withCommission && (body.with_commission = true);

    if (!id || typeof id === "string") {
        retailers = await api.catalogue.get("/retailers", {
            queryStringParameters: body
        });
    }
    else {
        retailers = await api.catalogue.post("/retailers", {
            body
        });
    }

    if (retailers && Array.isArray(retailers)) {
        return {
            data: retailers,
            error: null,
            hasMore: limit && retailers.length === limit
        }
    }
    else {
        return {
            data: [],
            error: "Failed to fetch retailers",
            hasMore: false
        }
    }
}
