import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

// import { useDispatch } from "react-redux"
import { useOn } from "@kuindji/observable-react";
import { App as AntApp, Dropdown, Input, Tooltip } from "antd";
import connectionsSelector from "common/src/selectors/connections";

// import Icon from "@ant-design/icons"

import { ReactComponent as IconAttach } from "common/src/svg/attach.svg";
import { ReactComponent as IconMoodboard } from "common/src/svg/bookmark.svg";
import { ReactComponent as IconLook } from "common/src/svg/look.svg";
import { ReactComponent as IconLooks } from "common/src/svg/looks.svg";
import { ReactComponent as IconMic } from "common/src/svg/mic.svg";
import { ReactComponent as IconPlus } from "common/src/svg/plus.svg";
import { ReactComponent as IconSearch } from "common/src/svg/search.svg";
import { ReactComponent as IconSend } from "common/src/svg/send.svg";

import Dropzone from "common/src/components/Dropzone";
import NullForm from "common/src/components/NullForm";
import ChatContext from "common/src/lib/chat/ChatContext";
import Button from "common/src/refactor/components/button/Button";
import AudioRecorder from "./AudioRecorder";

import useStateWithGetter from "common/src/hooks/useStateWithGetter";
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";
import audioRecorder from "common/src/lib/audio/recorder";
import async from "common/src/lib/js/async";
import user from "common/src/user";

function getFormattedDate() {
    const today = new Date();

    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();

    return `${day}_${month}_${year}`;
}

const AUDIO_SUPPORTED = audioRecorder.isSupported();
const MAX_CATALOGUE_ATTACHMENTS = 5;

function AttachmentsMenu({
    onAttachProductClick,
    onAttachMoodboardClick,
    onAttachFileClick,
    onAttachConsultationClick,
    onAttackLookClick,
    hasMoodboardsAccess,
    currentRole,
    hasCatalogueAccess,
    children,
}) {
    const menu = useMemo(() => {
        const items = [
            hasCatalogueAccess
                ? {
                    key: "product",
                    icon: <IconSearch />,
                    label: "Attach a product",
                    onClick: onAttachProductClick,
                }
                : null,
            currentRole === "concierge"
                ? null
                : {
                    key: "look",
                    icon: <IconLook />,
                    label: "Share a look",
                    onClick: onAttackLookClick,
                },
            currentRole === "concierge"
                ? null
                : {
                    key: "cons",
                    icon: <IconLooks />,
                    label: "Share a styling session",
                    onClick: onAttachConsultationClick,
                },
            hasMoodboardsAccess
                ? {
                    key: "moodboard",
                    icon: <IconMoodboard />,
                    label: "Attach a moodboard",
                    onClick: onAttachMoodboardClick,
                }
                : null,
            {
                key: "file",
                label: (
                    <Dropzone
                        clickOnly
                        multiple={true}
                        onChange={onAttachFileClick}
                        children={
                            <>
                                <IconAttach />
                                <div className="dropzone-label">
                                    Attach a file
                                </div>
                            </>
                        } />
                ),
            },
        ].filter((m) => !!m);

        return { items };
    }, []);

    return (
        <Dropdown menu={menu} trigger="click" rootClassName="context-menu">
            {children}
        </Dropdown>
    );
}

function FormInput() {
    const chat = useContext(ChatContext);
    // const dispatch = useDispatch();
    const { notifications, message: ntfMessage, modal } = AntApp.useApp();
    const [ text, setText, getText ] = useStateWithGetter("");
    const [ broadcastId, setBroadcastId ] = useState(() => getFormattedDate());
    const [ audio, setAudio ] = useState(null);
    const [ hasContact, setHasContact ] = useState(() => !!chat.getContact());
    const [ contact, setContact ] = useState(() => chat.getContact());
    const [ catalogueCnt, setCatalogueCnt ] = useState(0);
    const [ hasAttachments, setHasAttachments ] = useState(
        chat.getAttachments().length > 0,
    );
    const [ isAudioRecording, setIsAudioRecording ] = useState(false);
    const [ sending, setSending ] = useState(false);
    const connections = useSelector(connectionsSelector);

    const contactRole = contact?.role;
    const currentRole = chat.currentUserRole;

    const sendable = useMemo(
        () =>
            (!!text || hasAttachments)
            && (hasContact || currentRole === "concierge"),
        [ text, hasAttachments, hasContact ],
    );

    const hasCatalogueAccess = useMemo(
        () => user.isOnly("User") || user.hasAccessTo("tools/catalogue/*"),
        [ user ],
    );

    const hasMoodboardsAccess = useMemo(
        () => {
            if (user.isOnly("Admin") && currentRole !== "concierge") {
                return false;
            }
            return (
                user.is([ "Admin", "FRI", "GPS" ])
                || user.hasAccessTo("tools/moodboards/*")
            );
        },
        [ user ],
    );

    const submitMessage = useCallback(
        async () => {
            const text = getText();
            if (currentRole === "concierge" && !contact) {
                ntfMessage.success({ content: "Sending..." });
                await chat.sendBroadcast(text, broadcastId, connections);
                ntfMessage.success({ content: "Broadcast message was sent" });
            }
            else {
                chat.send(text);
            }
            setText("");
        },
        [ connections, currentRole, broadcastId ],
    );

    const onContactChange = useCallback(
        () => {
            setHasContact(!!chat.getContact());
            setContact(chat.getContact());
        },
        [],
    );

    const onAttachmentsChange = useCallback(
        () => {
            setHasAttachments(chat.getAttachments().length > 0);
            setAudio(chat.getAttachments("audio")[0]?.value);
            setCatalogueCnt(chat.getAttachments("catalogue").length);
        },
        [],
    );

    const onInputChange = useCallback(
        (e) => {
            setText(e.target.value);
            chat.setTyping();
        },
        [],
    );

    const onBroadcastIdChange = useCallback(
        (e) => {
            setBroadcastId(e.target.value);
        },
        [],
    );

    const onSubmitClick = useSwallowEventCallback(
        () => {
            if (currentRole === "concierge" && !contact) {
                modal.confirm({
                    closable: true,
                    icon: null,
                    title: null,
                    content: "Do you really want to send this message?",
                    cancelButtonProps: {
                        type: "text",
                    },
                    okText: "Send",
                    onOk: () => {
                        return async(submitMessage);
                    },
                    onCancel: () => setSending(false),
                });
            }
            else {
                async(submitMessage);
            }
            setSending(true);
        },
        [ submitMessage, currentRole ],
    );

    const onCatalogueClick = useCallback(
        () => {
            const res = chat.first("attachFromFeed");
            if (res instanceof Promise) {
                res.then(({ products }) => {
                    products.forEach((p) =>
                        chat.addAttachment("catalogue", p, p.id)
                    );
                });
            }
        },
        [],
    );

    const onMoodboardsClick = useCallback(
        () => {
            const res = chat.first("attachMoodboard");
            if (res instanceof Promise) {
                res.then((moodboards) => {
                    moodboards.forEach((m) =>
                        chat.addAttachment("moodboard", m, m.id)
                    );
                });
            }
        },
        [],
    );

    const onConsultationsClick = useCallback(
        () => {
            const res = chat.first("attachConsultation");
            if (res instanceof Promise) {
                res.then((cons) => {
                    cons.forEach((c) =>
                        chat.addAttachment("consultation", c, c.id)
                    );
                });
            }
        },
        [],
    );

    const onLooksClick = useCallback(
        () => {
            const res = chat.first("attachLook");
            if (res instanceof Promise) {
                res.then((looks) => {
                    looks.forEach((l) => chat.addAttachment("look", l, l.id));
                });
            }
        },
        [],
    );

    const addImageUpload = useCallback(
        async (files) => {
            if (Array.isArray(files)) {
                for (let i = 0; i < 5; i++) {
                    const img = await chat.readInputImage(files[i]);
                    chat.addAttachment("image", img, img.localId);
                }
            }
            else {
                const img = await chat.readInputImage(files);
                chat.addAttachment("image", img, img.localId);
            }
        },
        [],
    );

    const onMicClick = useSwallowEventCallback(
        () => {
            setIsAudioRecording(true);
        },
        [],
    );

    const onAudioError = useCallback(
        (code) => {
            switch (code) {
                case "NotAllowedError": {
                    notifications.error({
                        message: "Access to microphone is not granted.",
                    });
                    break;
                }
                default: {
                    notifications.error({
                        message: "Failed to make a recording.",
                    });
                }
            }
        },
        [],
    );

    const onAudioRecorded = useCallback(
        (blob) => {
            chat.removeAttachment("audio");
            chat.addAttachment("audio", {
                blob,
                src: URL.createObjectURL(blob),
            });
            onSubmitClick();
        },
        [],
    );

    const onUpdateDuration = useCallback(() => chat.setRecording(), []);

    useEffect(
        () => {
            onContactChange();
        },
        [],
    );

    const MessageInputElement = currentRole === "concierge" && !contact
        ? Input.TextArea
        : Input;

    useOn(chat, {
        attachments: onAttachmentsChange,
        // creatingMessage: setSending,
        contact: onContactChange,
        sending: setSending,
    });

    return (
        <NullForm className="chat-form-input">
            <div
                className={`${
                    currentRole === "concierge" && !contact
                        ? "chat-form-input-group chat-form-input-group-broadcast"
                        : "chat-form-input-group"
                }`}>
                {!isAudioRecording && (
                    <MessageInputElement
                        rows={1}
                        size="large"
                        disabled={contactRole === "concierge" || sending}
                        placeholder="Send a message"
                        className="chat-form-input-field"
                        onPressEnter={!sendable
                                || sending
                                || currentRole === "concierge"
                                || contactRole === "concierge"
                            ? null
                            : submitMessage}
                        value={text}
                        onChange={onInputChange} />
                )}
                {isAudioRecording && (
                    <AudioRecorder
                        onError={onAudioError}
                        onRecord={onAudioRecorded}
                        onUpdateDuration={onUpdateDuration}
                        isRecording={isAudioRecording}
                        setIsRecording={setIsAudioRecording} />
                )}

                {currentRole === "concierge" && !contact && (
                    <Input
                        size="large"
                        disabled={currentRole !== "concierge" || sending}
                        placeholder="Broadcast ID"
                        className="chat-form-input-field chat-form-input-broadcast"
                        value={broadcastId}
                        onChange={onBroadcastIdChange} />
                )}
                {sendable && (
                    <Button
                        size="large"
                        disabled={!sendable || sending
                            || contactRole === "concierge"}
                        className="chat-form-input-button"
                        type="transparent"
                        onClick={onSubmitClick}
                        loading={sending}
                        Icon={IconSend} />
                )}
                {!sendable && !isAudioRecording && (
                    <div className="chat-form-input-actions">
                        {contactRole !== "concierge" && (
                            <AttachmentsMenu
                                onAttachProductClick={onCatalogueClick}
                                onAttachMoodboardClick={onMoodboardsClick}
                                onAttachFileClick={addImageUpload}
                                onAttachConsultationClick={onConsultationsClick}
                                onAttackLookClick={onLooksClick}
                                currentRole={currentRole}
                                hasCatalogueAccess={hasCatalogueAccess}
                                hasMoodboardsAccess={hasMoodboardsAccess}>
                                <Button
                                    size="large"
                                    disabled={sending}
                                    className="chat-form-input-button"
                                    type="transparent"
                                    Icon={IconPlus} />
                            </AttachmentsMenu>
                        )}
                        {AUDIO_SUPPORTED && currentRole !== "concierge" && (
                            <Tooltip title="Audio message">
                                <Button
                                    size="large"
                                    disabled={sending
                                        || contactRole === "concierge"
                                        || currentRole === "concierge"}
                                    className="chat-form-input-button"
                                    type="transparent"
                                    Icon={IconMic}
                                    onClick={onMicClick} />
                            </Tooltip>
                        )}
                    </div>
                )}
            </div>
        </NullForm>
    );
}

export default FormInput;
