import { Flex, Modal, Spin } from "antd";
import { useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import Button from "common/src/refactor/components/button/Button";
import ProductCard from "common/src/refactor/components/catalogue/product/Card";
import CatalogueProductSelect from "common/src/refactor/components/catalogue/product/Select";
import EmptyMessage from "common/src/refactor/components/EmptyMessage";
// import FeedDialogToolbar from "./FeedDialogToolbar"
import PartitionToolbar from "common/src/refactor/components/catalogue/PartitionToolbar";
import FeedSearchBar from "common/src/refactor/components/catalogue/SearchBar";
import { ReactComponent as IconUp } from "common/src/svg/angle-up.svg";

import { FilterApiContextProvider } from "common/src/refactor/lib/FilterApi";

import store from "app/store";
import useProductCatalogue from "common/src/refactor/hooks/useProductCatalogue";
import { ui } from "common/src/store/dialogs";
// import useContextData from "common/src/refactor/hooks/useContextData"
import useDictRef from "common/src/hooks/useDictRef";
import BackToTop from "common/src/refactor/components/catalogue/BackToTop";
import useSelection from "common/src/refactor/hooks/useSelection";

import FeedMessages from "common/src/refactor/components/catalogue/FeedMessages";
import SideFilters from "common/src/refactor/components/catalogue/SideFilters";

import FilterApi from "common/src/refactor/lib/FilterApi";

const footerProps = {
    addNameSpacing: true,
};

const selectionPromise = {};

const DIALOG_NAME = "feed-dialog";

export function Feed({ dialogName }) {
    const filters = useMemo(() => new FilterApi(), []);
    const { selection, map, add, remove } = useSelection({
        mapFn: (p) => p.id,
        comparisonFn: (a, b) => a.id === b.id,
    });
    const selectionCnt = useMemo(
        () => Object.keys(selection).length,
        [ selection ],
    );
    const bodyRef = useRef(null);

    const {
        products,
        loadMore,
        hasMore,
        loading,
        loaded,
        messages,
        takingTooLong,
        isError,
    } = useProductCatalogue({ filters, setName: "feed-dialog" });

    const ref = useDictRef({ hasMore, loading, loadMore });

    const onClose = useCallback(
        () => FeedDialog.hide(dialogName),
        [ dialogName ],
    );

    const onSelectionChange = useCallback(
        (id, selected) => {
            const product = products.find((p) => p.id === id);
            selected ? add(product) : remove(product);
        },
        [ products, add, remove ],
    );

    const onScroll = useCallback(
        (e) => {
            const el = e.target;
            if (ref.hasMore && !ref.loading) {
                if (el.scrollTop / (el.scrollHeight - el.offsetHeight) > 0.75) {
                    ref.loadMore();
                }
            }
        },
        // eslint-disable-next-line
        [],
    );

    const onSelectClick = useCallback(
        () => {
            if (selectionPromise[dialogName]) {
                selectionPromise[dialogName]({ products: selection });
            }

            FeedDialog.hide(dialogName);
        },
        [ selection, dialogName ],
    );

    return (
        <FilterApiContextProvider value={filters}>
            <Flex gap="1rem" className="feed-dialog-layout">
                <SideFilters useQueryString={false} />
                <Flex vertical gap="0.5rem">
                    <Flex
                        vertical
                        gap="0.5rem"
                        style={{ paddingTop: "1rem", paddingRight: "1rem" }}>
                        <FeedSearchBar onClose={dialogName ? onClose : null} />
                        <PartitionToolbar toolbarSize="small" />
                    </Flex>

                    <div className="feed-dialog-body" onScroll={onScroll}>
                        <FeedMessages messages={messages} />
                        {loaded && !loading && products.length === 0 && (
                            <EmptyMessage
                                size="small"
                                message="No products matched your search" />
                        )}
                        <div
                            className="feed-dialog-products"
                            style={{ paddingRight: "1rem" }}>
                            {products.map((p) => (
                                <ProductCard
                                    key={p.id}
                                    product={p}
                                    footer={footerProps}
                                    children={
                                        <CatalogueProductSelect
                                            id={p.id}
                                            selected={map[p.id] || false}
                                            onChange={onSelectionChange} />
                                    } />
                            ))}
                        </div>
                        {(hasMore || !loaded) && !isError && (
                            <Spin
                                tip={takingTooLong
                                    ? "Sorry, this is taking a bit longer than expected"
                                    : undefined}
                                children={<>&nbsp;</>}
                                spinning
                                className="infinite-scroll-spinner"
                                wrapperClassName="infinite-scroll-spinner-wrapper" />
                        )}
                        {isError && products.length > 0 && (
                            <FeedMessages messages={messages} type="error" />
                        )}
                    </div>
                </Flex>
            </Flex>

            <div className="feed-dialog-footer">
                <Button
                    type="primary"
                    text={`Select (${selectionCnt})`}
                    onClick={onSelectClick}
                    disabled={selectionCnt === 0} />
                <BackToTop elemRef={bodyRef}>
                    <Button
                        type="text"
                        size="medium"
                        text="Back to top"
                        variant="link"
                        Icon={IconUp}
                        iconPosition="after" />
                </BackToTop>
            </div>
        </FilterApiContextProvider>
    );
}

function FeedDialog({ dialogName = DIALOG_NAME }) {
    const open = useSelector((s) => s.dialogs[dialogName] || false);

    const onClose = useCallback(
        () => void FeedDialog.hide(dialogName),
        [ dialogName ],
    );

    return (
        <Modal
            classNames={{ wrapper: "feed-dialog" }}
            centered
            width="66.5rem"
            open={open}
            destroyOnClose
            closeIcon={null}
            footer={null}
            maskClosable={false}
            onCancel={onClose}>
            <Feed dialogName={dialogName} />
        </Modal>
    );
}

FeedDialog.show = function(name = DIALOG_NAME) {
    store.dispatch(ui.show(name));
    return new Promise((resolve) => {
        selectionPromise[name] = resolve;
    });
};

FeedDialog.hide = function(name = DIALOG_NAME) {
    selectionPromise[name] && selectionPromise[name]([]);
    store.dispatch(ui.hide(name));
    delete selectionPromise[name];
};

export default FeedDialog;
